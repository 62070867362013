import {
  TOKEN_EXCHANGE_SUCCESS_TEXT,
  TOKEN_EXCHANGE_TRIGGER_TEXT,
} from "@/constants.js";
import { loggers } from "@/lib/logger.js";
import {
  displayModeFromState,
  serverTokenExchangeFromState,
} from "@/lib/oauth.js";
import type { AuthConfig } from "@/nextjs/config.js";
import { resolveAuthConfig } from "@/nextjs/config.js";
import {
  clearAuthCookies,
  NextjsClientStorage,
  NextjsCookieStorage,
} from "@/nextjs/cookies.js";
import { getUser } from "@/nextjs/index.js";
import { resolveCallbackUrl } from "@/nextjs/utils.js";
import { resolveOAuthAccessCode } from "@/server/login.js";
import { GenericPublicClientPKCEProducer } from "@/services/PKCE.js";
import { AuthenticationRefresherImpl } from "@/shared/lib/AuthenticationRefresherImpl.js";
import { CodeVerifier, OAuthTokens } from "@/shared/lib/types.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";
import { generateOauthLogoutUrl } from "@/shared/lib/util.js";
import { revalidatePath } from "next/cache.js";
import type { NextRequest } from "next/server.js";
import { NextResponse } from "next/server.js";

const logger = loggers.nextjs.handlers.auth;

class AuthError extends Error {
  constructor(
    message: string,
    public readonly status: number = 401,
  ) {
    super(message);
    this.name = "AuthError";
  }
}

const getAppUrl = (request: NextRequest): string | null =>
  request.cookies.get(CodeVerifier.APP_URL)?.value ||
  request.nextUrl.searchParams.get("appUrl");

const getIdToken = async (config: AuthConfig): Promise<string | null> => {
  const cookieStorage = new NextjsCookieStorage(config.cookies?.tokens ?? {});
  return cookieStorage.get(OAuthTokens.ID_TOKEN);
};

/**
 * create a code verifier and challenge for PKCE
 * saving the verifier in a cookie for later use
 * @returns {Promise<NextResponse>}
 */
async function handleChallenge(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const cookieStorage = new NextjsCookieStorage(config.cookies?.tokens ?? {});
  const pkceProducer = new GenericPublicClientPKCEProducer(cookieStorage);

  const challenge = await pkceProducer.getCodeChallenge();
  const appUrl = request.nextUrl.searchParams.get("appUrl");
  if (appUrl) {
    cookieStorage.set(CodeVerifier.APP_URL, appUrl);
  }
  return NextResponse.json({ status: "success", challenge });
}

async function performTokenExchangeAndSetCookies(
  request: NextRequest,
  config: AuthConfig,
  code: string,
  state: string,
  appUrl: string,
) {
  const resolvedConfigs = resolveAuthConfig(config);
  const cookieStorage = new NextjsCookieStorage(resolvedConfigs.cookies.tokens);

  const callbackUrl = resolveCallbackUrl(resolvedConfigs, appUrl);
  try {
    await resolveOAuthAccessCode(code, state, cookieStorage, {
      ...resolvedConfigs,
      redirectUrl: callbackUrl,
    });
  } catch (error) {
    logger.error("Token exchange failed:", error);
    throw new AuthError("Failed to authenticate user", 401);
  }

  const user = await getUser();
  if (!user) {
    throw new AuthError("Failed to get user info", 401);
  }

  const clientStorage = new NextjsClientStorage();
  const userSession = new GenericUserSession(clientStorage);
  userSession.set(user);
}
async function handleRefresh(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);
  const cookieStorage = new NextjsCookieStorage(config.cookies?.tokens ?? {});

  const refresher = await AuthenticationRefresherImpl.build(
    {
      clientId: resolvedConfigs.clientId,
      oauthServer: resolvedConfigs.oauthServer,
      redirectUrl: resolvedConfigs.callbackUrl,
      refreshUrl: resolvedConfigs.refreshUrl,
    },
    cookieStorage,
  );
  const tokens = await refresher.refreshAccessToken();

  // this will use the refresh token to get new tokens and, if successful
  // the idToken, accessToken and user cookies will be updated
  // await newRefresher.refreshTokens();
  return NextResponse.json({ status: "success", tokens });
}

const generateHtmlResponseWithCallback = (
  request: NextRequest,
  callbackUrl: string,
) => {
  // we need to replace the URL with resolved config in case the server is hosted
  // behind a reverse proxy or load balancer
  const requestUrl = new URL(request.url);
  const fetchUrl = `${callbackUrl}?${requestUrl.searchParams.toString()}&sameDomainCallback=true`;
  return new NextResponse(
    `<html>
         <body>
             <span style="display:none">
                 <script>
                     window.onload = function () {
                         const appUrl = globalThis.window?.location?.origin;
                         fetch('${fetchUrl}&appUrl=' + appUrl).then((response) => {
                             response.json().then((jsonResponse) => {
                                 if (jsonResponse.redirectUrl) {
                                     window.location.href = jsonResponse.redirectUrl;
                                 }
                             });
                         });
                     };
                 </script>
             </span>
         </body>
     </html>
    `,
  );
};

async function handleCallback(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);
  const code = request.nextUrl.searchParams.get("code");
  const state = request.nextUrl.searchParams.get("state");
  if (!code || !state) throw new AuthError("Bad parameters", 400);

  // appUrl is passed from the client to the server in the query string
  // this is necessary because the server does not have access to the client's window.location.origin
  // and can not accurately determine the appUrl (specially if the app is behind a reverse proxy)
  const appUrl = getAppUrl(request);

  // If we have a code_verifier cookie and the appUrl, we can do a token exchange.
  // Otherwise, just render an empty page.
  // The initial redirect back from the auth server does not send cookies, because the redirect is from a 3rd-party domain.
  // The client will make an additional call to this route with cookies included, at which point we do the token exchange.
  const codeVerifier = request.cookies.get(CodeVerifier.COOKIE_NAME);

  if (!codeVerifier || !appUrl) {
    logger.debug("handleCallback no code_verifier found", {
      state,
      serverTokenExchange: serverTokenExchangeFromState(`${state}`),
    });
    let response = new NextResponse(
      `<html><body><span style="display:none">${TOKEN_EXCHANGE_TRIGGER_TEXT}</span></body></html>`,
    );

    // in server-side token exchange mode we need to launch a page that will trigger the token exchange
    // from the same domain, allowing it access to the code_verifier cookie
    // we only need to do this in redirect mode, as the iframe already triggers a client-side token exchange
    // if no code-verifier cookie is found
    if (state && serverTokenExchangeFromState(state)) {
      logger.debug(
        "handleCallback serverTokenExchangeFromState, launching redirect page...",
        {
          requestUrl: request.url,
          configCallbackUrl: resolvedConfigs.callbackUrl,
        },
      );
      // generate a page that will callback to the same domain, allowing access
      // to the code_verifier cookie and passing the appUrl.
      response = generateHtmlResponseWithCallback(
        request,
        resolvedConfigs.callbackUrl,
      );
    }

    response.headers.set("Content-Type", "text/html; charset=utf-8");
    logger.debug(
      `handleCallback no code_verifier found, returning ${TOKEN_EXCHANGE_TRIGGER_TEXT}`,
    );
    return response;
  }

  await performTokenExchangeAndSetCookies(
    request,
    resolvedConfigs,
    code,
    state,
    appUrl,
  );

  if (request.url.includes("sameDomainCallback=true")) {
    logger.debug(
      "handleCallback sameDomainCallback = true, returning redirectUrl",
      appUrl,
    );
    return NextResponse.json({
      status: "success",
      redirectUrl: appUrl,
    });
  }

  // this is the case where a 'normal' redirect is happening
  if (serverTokenExchangeFromState(state)) {
    logger.debug(
      "handleCallback serverTokenExchangeFromState, redirect to appUrl",
      appUrl,
    );
    if (!appUrl) {
      throw new Error("appUrl undefined. Cannot redirect.");
    }
    return NextResponse.redirect(`${appUrl}`);
  }
  // return an empty HTML response so the iframe doesn't show any response
  // in the short moment between the redirect and the parent window
  // acknowledging the redirect and closing the iframe
  const response = new NextResponse(
    `<html><span style="display:none">${TOKEN_EXCHANGE_SUCCESS_TEXT}</span></html>`,
  );
  response.headers.set("Content-Type", "text/html; charset=utf-8");
  return response;
}

/**
 * If redirectPath is an absolute path, return it as-is.
 * Otherwise for relative paths, append it to the current domain.
 * @param redirectPath
 * @returns
 */
const getAbsoluteRedirectPath = (
  redirectPath: string,
  currentBasePath: string,
) => new URL(redirectPath, currentBasePath).href;

const getPostLogoutRedirectUrl = (
  request: NextRequest,
  config: AuthConfig,
): string | null => {
  const { loginUrl } = resolveAuthConfig(config);
  const redirectTarget = loginUrl ?? "/";

  // if the optional loginUrl is provided and it is an absolute URL,
  // use it as the redirect target
  const isAbsoluteRedirect = /^(https?:\/\/|www\.).+/i.test(redirectTarget);
  if (isAbsoluteRedirect) {
    return redirectTarget;
  }

  // if loginUrl is not defined, the appUrl is passed from the client to the server
  // in the query string or cookies. This is necessary because the server does not
  // have access to the client's window.location and can not accurately determine
  // the appUrl (specially if the app is behind a reverse proxy).
  const appUrl = getAppUrl(request);
  if (appUrl) return getAbsoluteRedirectPath(redirectTarget, appUrl);

  return null;
};

const revalidateUrlPath = async (url: string) => {
  try {
    const path = new URL(url).pathname;
    revalidatePath(path);
  } catch (error) {
    logger.warn("Failed to revalidate path after logout:", error);
  }
};

export async function handleLogout(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);

  // read the id_token from the cookies
  const idToken = await getIdToken(resolvedConfigs);

  // read the state from the query parameters
  const state = request.nextUrl.searchParams.get("state");

  if (!state || !idToken) throw new AuthError(`Bad parameters`, 400);

  const postLogoutUrl = new URL(
    resolvedConfigs.logoutCallbackUrl,
    getAppUrl(request) || request.url,
  );
  const logoutUrl = await generateOauthLogoutUrl({
    clientId: resolvedConfigs.clientId,
    idToken,
    state,
    redirectUrl: postLogoutUrl.href,
    oauthServer: resolvedConfigs.oauthServer,
  });

  return NextResponse.redirect(`${logoutUrl.href}`);
}

export async function handleLogoutCallback(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);
  const state = request.nextUrl.searchParams.get("state") || "";
  if (!state) throw new AuthError("Bad parameters", 400);

  const displayMode = displayModeFromState(state, "iframe");

  const canAccessCookies = !!(await getIdToken(resolvedConfigs));
  if (canAccessCookies) {
    await clearAuthCookies(resolvedConfigs);
  }

  let response;

  // handle logout for iframe display mode
  if (displayMode === "iframe") {
    // try to read the token from cookies. If cookies cant be read/written
    // because the request cames from a cross-origin redirect,
    // we need to show a page that will trigger the logout from the same domain
    if (canAccessCookies || request.url.includes("sameDomainCallback=true")) {
      // just return success
      return NextResponse.json({ status: "success" });
    }

    // return a page that will trigger the logout from the same domain
    response = generateHtmlResponseWithCallback(
      request,
      resolvedConfigs.logoutCallbackUrl,
    );
    response.headers.set("Content-Type", "text/html; charset=utf-8");
    return response;
  }

  // handle logout for non-iframe display mode
  const redirectUrl = getPostLogoutRedirectUrl(request, resolvedConfigs);

  if (redirectUrl && canAccessCookies) {
    // this is comming from the fetch from the HTML page returned by this handler
    if (request.url.includes("sameDomainCallback=true")) {
      logger.debug(
        "handleCallback sameDomainCallback = true, returning redirectUrl",
        redirectUrl,
      );
      return NextResponse.json({
        status: "success",
        redirectUrl: redirectUrl,
      });
    }

    // just redirect to the app url
    response = NextResponse.redirect(`${redirectUrl}`);
    revalidateUrlPath(redirectUrl);
  } else {
    logger.debug("handleLogout no redirectUrl found", { state });
    response = generateHtmlResponseWithCallback(
      request,
      resolvedConfigs.logoutCallbackUrl,
    );
    response.headers.set("Content-Type", "text/html; charset=utf-8");
  }

  return response;
}

/**
 * Creates an authentication handler for Next.js API routes
 *
 * Usage:
 * ```ts
 * // app/api/auth/[...civicauth]/route.ts
 * import { handler } from '@civic/auth/nextjs'
 * export const GET = handler({
 *   // optional config overrides
 * })
 * ```
 */
export const handler =
  (authConfig = {}) =>
  async (request: NextRequest): Promise<NextResponse> => {
    const config = resolveAuthConfig(authConfig);

    try {
      const pathname = request.nextUrl.pathname;
      const pathSegments = pathname.split("/");
      const lastSegment = pathSegments[pathSegments.length - 1];

      switch (lastSegment) {
        case "challenge":
          return await handleChallenge(request, config);
        case "callback":
          return await handleCallback(request, config);
        case "refresh":
          return await handleRefresh(request, config);
        case "logout":
          return await handleLogout(request, config);
        case "logoutcallback":
          return await handleLogoutCallback(request, config);
        default:
          throw new AuthError(`Invalid auth route: ${pathname}`, 404);
      }
    } catch (error) {
      logger.error("Auth handler error:", error);

      const status = error instanceof AuthError ? error.status : 500;
      const message =
        error instanceof Error ? error.message : "Authentication failed";

      const response = NextResponse.json({ error: message }, { status });

      clearAuthCookies(config);
      return response;
    }
  };
