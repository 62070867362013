import type {
  Account,
  Chain,
  ProviderConnectInfo,
  PublicClient,
  RpcSchema,
  Transport,
  WalletClient,
} from "viem";
import type { Connector } from "wagmi";
import type { TurnkeySDKApiTypes } from "@turnkey/sdk-browser";
import { TurnkeyWalletProvider } from "./lib/turnkey/TurnkeyWalletProvider.js";
import type { User } from "@civic/auth";
import type {
  CivicAuthProviderProps,
  UserContextType,
} from "@civic/auth/react";
import type { AuthProviderProps as NextCivicAuthProviderProps } from "@civic/auth/nextjs";

export type NextCivicAuthWeb3ProviderProps = Omit<
  NextCivicAuthProviderProps,
  "redirectUri" | "endpoints"
> &
  Web3ProviderProps & { onSessionEnd?: () => void };

export type CivicAuthWeb3ProviderProps = Omit<
  CivicAuthProviderProps,
  "redirectUri" | "endpoints"
> &
  Web3ProviderProps & { onSessionEnd?: () => void };

export type Web3ProviderProps = {
  autoCreateWallet?: boolean;
  autoConnectEmbeddedWallet?: boolean;
  endpoints?: {
    auth?: string;
    token?: string;
    wallet?: string;
  };
  turnkey?: {
    baseUrl?: string;
    defaultOrganizationId?: string;
    serverSignUrl?: string;
    rpId?: string;
    iframeUrl?: string;
  };
};

export type TurnkeyConfig = {
  apiBaseUrl: string;
  defaultOrganizationId: string;
  serverSignUrl: string;
  rpId: string;
  iframeUrl: string;
};

export type CivicApiClientConfig = {
  endpoints?: {
    wallet?: string;
    analytics?: string;
    rpcs?: Record<number, string>;
  };
  turnkey?: Partial<TurnkeyConfig>;
};

export type ApiClientConfig = {
  endpoints: {
    wallet: string;
    analytics: string;
    rpcs: Record<number, string>;
  };
  turnkey: TurnkeyConfig;
};

export type CreateSubOrganisationResponse = {
  subOrgId: string;
  walletId: string;
  walletAddress: string;
};

export type SparseWallet = {
  walletId: string;
  walletAddress: string;
};
export type GetWalletsResponse = SparseWallet[];

// A wagmi wallet provider that wraps a viem client
export interface ViemClientProvider<
  transport extends Transport,
  chain extends Chain | undefined,
  account extends Account | undefined,
  rpcSchema extends RpcSchema | undefined,
> {
  // used for wallet-specific operations
  client: WalletClient<transport, chain, account, rpcSchema> | undefined;
  // used for unauthenticated RPC calls
  publicClient: PublicClient<transport, chain, account, rpcSchema> | undefined;
}

export type TurnkeyConnectorProperties = {
  onConnect(connectInfo: ProviderConnectInfo): void;
  getProvider(): Promise<TurnkeyWalletProvider | undefined>;
  ready: boolean;
};

export type ViemConnector = {
  setViemClient(client: WalletClient): void;
};

export type TurnkeyConnector = Connector & TurnkeyConnectorProperties;

export type UserTurnkeyLoginInfo = {
  wallets: TurnkeySDKApiTypes.TGetWalletsResponse["wallets"];
  subOrgId: string;
  user: TurnkeySDKApiTypes.TGetUsersResponse["users"][0];
};
export type UnknownObject = Record<string, unknown>;
export type Web3User = {
  walletAddress: string | null;
  wallet: WalletClient | null;
};
type BaseContext<T extends UnknownObject> = UserContextType & {
  walletCreationInProgress: boolean;
  user: User<T> | null;
};
export type ExistingWeb3UserContext<T extends UnknownObject = UnknownObject> =
  BaseContext<T> & Web3User;

export type NewWeb3UserContext<T extends UnknownObject = UnknownObject> =
  BaseContext<T> & {
    createWallet: () => Promise<void>;
    walletCreationInProgress: boolean;
  };

export type Web3UserContextType<T extends UnknownObject = UnknownObject> =
  | NewWeb3UserContext<T>
  | ExistingWeb3UserContext<T>;

export enum OAuthTokens {
  ID_TOKEN = "id_token",
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  EXPIRES_IN = "expires_in",
  TIMESTAMP = "timestamp",
}
export enum CodeVerifier {
  COOKIE_NAME = "code_verifier",
  APP_URL = "app_url",
}
