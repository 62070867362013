"use client";
import React from "react";
import type { DisplayMode } from "@/types.js";
import { useUser } from "@/reactjs/hooks/useUser.js";
import { ButtonContentOrLoader } from "./ButtonContentOrLoader.js";

const SignInButton = ({
  displayMode,
  className,
}: {
  displayMode?: DisplayMode;
  className?: string;
}) => {
  const { signIn, authStatus, displayMode: userDisplayMode } = useUser();

  return (
    <button
      data-testid="sign-in-button"
      style={{
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        transition: "background-color 0.2s",
        minWidth: "9em", // this stops the button from going too small when in loading mode
      }}
      className={className}
      onClick={() => signIn(displayMode)}
    >
      <ButtonContentOrLoader
        authStatus={authStatus}
        displayMode={displayMode || userDisplayMode}
      >
        Sign In
      </ButtonContentOrLoader>
    </button>
  );
};

export { SignInButton };
