import type { Endpoints } from "@/types.js";

export enum OAuthTokens {
  ID_TOKEN = "id_token",
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  EXPIRES_IN = "expires_in",
  TIMESTAMP = "timestamp",
}

export enum CodeVerifier {
  COOKIE_NAME = "code_verifier",
  APP_URL = "app_url",
}
export enum UserStorage {
  USER = "user",
}
export interface CookieConfig {
  secure?: boolean;
  sameSite?: "strict" | "lax" | "none";
  domain?: string;
  path?: string;
  maxAge?: number;
  httpOnly?: boolean;
}

export type TokensCookieConfig = Record<
  OAuthTokens | CodeVerifier,
  CookieConfig
>;

export type CivicAuthConfig = null | {
  clientId: string;
  redirectUrl: string;
  logoutRedirectUrl: string;
  oauthServer: string;
  endpoints: Endpoints;
  scopes: string[];
  nonce?: string;
  challengeUrl?: string;
  refrershUrl?: string;
  logoutUrl?: string;
};
