/**
 * Merges two objects, using values from the override object only when they are not null or undefined.
 * Values from the override object will replace values from the default object only if they exist and are not null.
 *
 * @param defaultObj The default object to use as a base
 * @param overrideObj The object containing override values
 * @returns A new object with merged values
 */
export function mergeWithDefault<T extends object>(
  defaultObj: T,
  overrideObj?: Partial<T>,
): T {
  if (!overrideObj) return { ...defaultObj };

  return {
    ...defaultObj,
    ...(Object.fromEntries(
      Object.entries(overrideObj).filter(([, value]) => value != null),
    ) as Partial<T>),
  };
}
