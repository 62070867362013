"use client";
import { useContext } from "react";
import { CivicAuthConfigContext } from "@/shared/providers/CivicAuthConfigContext.js";

// TokenProvider will use this internal context to access Config
const useCivicAuthConfig = () => {
  const context = useContext(CivicAuthConfigContext);
  return context;
};

export { useCivicAuthConfig };
