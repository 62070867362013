"use client";
import type { ReactNode } from "react";
import React, { createContext, useMemo } from "react";
import { useSession } from "@/shared/hooks/useSession.js";
import type { ForwardedTokens, IdToken } from "@/types.js";
import { parseJWT } from "oslo/jwt";
import { convertForwardedTokenFormat } from "@/lib/jwt.js";

type TokenContextType = {
  accessToken: string | null;
  idToken: string | null;
  forwardedTokens: ForwardedTokens;
  isLoading: boolean;
  error: Error | null;
};

const TokenContext = createContext<TokenContextType | undefined>(undefined);

const TokenProvider = ({ children }: { children: ReactNode }) => {
  const { isLoading, error: authError } = useSession();
  const { data: session } = useSession();

  const decodeTokens = useMemo(() => {
    if (!session?.idToken) return null;

    const parsedJWT = parseJWT(session.idToken) as IdToken | null;

    if (!parsedJWT) return null;

    const { forwardedTokens } = parsedJWT.payload;

    return forwardedTokens
      ? convertForwardedTokenFormat(forwardedTokens)
      : null;
  }, [session?.idToken]);

  const value = useMemo(
    () => ({
      accessToken: session?.accessToken || null,
      idToken: session?.idToken || null,
      forwardedTokens: decodeTokens || {},
      isLoading,
      error: authError as Error | null,
    }),
    [
      session?.accessToken,
      session?.idToken,
      decodeTokens,
      isLoading,
      authError,
    ],
  );

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
};

export type { TokenContextType };
export { TokenProvider, TokenContext };
