/// A wagmi wallet provider that wraps a turnkey viem client.
/// Used internally only (by the Turnkey Connector), should not be exported.
import type { ViemClientProvider } from "../../types.js";
import {
  type Chain,
  createPublicClient,
  createWalletClient,
  type LocalAccount,
  type PublicClient,
  type SendTransactionParameters,
  type SignableMessage,
  type SignTypedDataParameters,
  type Transport,
  type WalletClient,
} from "viem";
import { viemTransport } from "./turnkeyApiClient.js";
import { getTransportForChain } from "../walletUtils.js";
import { normaliseEthRequestParams } from "../transactionUtils.js";

const cloneWalletClientWithNewChain = (
  client: WalletClient<Transport, Chain, LocalAccount>,
  chain: Chain,
  config: { rpcs?: Record<number, string> },
) => {
  return createWalletClient({
    chain,
    transport: getTransportForChain(config.rpcs ?? {})(chain),
    account: client.account,
  });
};

export class TurnkeyWalletProvider
  implements ViemClientProvider<Transport, Chain, LocalAccount, undefined>
{
  // used for wallet-specific operations
  client: WalletClient<Transport, Chain, LocalAccount> | undefined;
  // used for unauthenticated RPC calls
  publicClient: PublicClient<Transport, Chain, LocalAccount> | undefined;

  constructor(
    private config: {
      rpcs?: Record<number, string>;
    },
  ) {}

  setClient(client: WalletClient<Transport, Chain, LocalAccount>) {
    this.client = client;
    this.publicClient = createPublicClient({
      chain: client.chain,
      transport: viemTransport,
    });
  }

  getChainId(): number {
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    if (!this.client.chain)
      throw new Error("Turnkey client is not connected to a chain");
    return this.client.chain.id;
  }

  getAccount() {
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    return this.client.account;
  }

  setChain(chain: Chain) {
    if (!this.client) {
      throw new Error("Turnkey provider is not initialized");
    }
    this.setClient(
      cloneWalletClientWithNewChain(this.client, chain, this.config),
    );
  }

  enable(): Promise<string[]> {
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    return this.client.getAddresses();
  }

  // RPC method params are not typed at present

  async request({
    method,
    params,
  }: {
    method: string;
    params: unknown[];
  }): Promise<unknown> {
    if (!this.client || !this.publicClient)
      throw new Error("Turnkey provider is not initialized");

    switch (method) {
      // **Wallet-Specific Methods**
      case "eth_accounts":
        return this.client.getAddresses();
      case "eth_chainId":
        // eslint-disable-next-line no-case-declarations
        const chainId = await this.client.getChainId();
        return `0x${chainId.toString(16)}`;
      case "eth_sendTransaction":
        // eslint-disable-next-line no-case-declarations
        const sendTransactionParams = normaliseEthRequestParams(
          params[0],
        ) as SendTransactionParameters;
        return this.client.sendTransaction(sendTransactionParams);
      case "eth_sign":
      case "personal_sign":
        // eslint-disable-next-line no-case-declarations
        const [signMessageParameters] = params as [SignableMessage];
        return this.client.signMessage({ message: signMessageParameters });
      case "eth_signTypedData":
      case "eth_signTypedData_v4":
        // eslint-disable-next-line no-case-declarations
        const [, typedDataSerialized] = params as [string, string];
        // eslint-disable-next-line no-case-declarations
        const typedData = JSON.parse(
          typedDataSerialized,
        ) as SignTypedDataParameters;
        // eslint-disable-next-line no-case-declarations
        const signTypedData = await this.client.signTypedData(typedData);
        return signTypedData;
      // **All Other Methods**
      default:
        // Use PublicClient for read-only or network calls
        return this.publicClient.request({
          method,
          params,
        } as Parameters<typeof this.publicClient.request>[0]);
    }
  }
}
