"use client";
import type { Dispatch, ReactNode, RefObject, SetStateAction } from "react";
import React, { createContext, useRef, useState } from "react";
import { useIsInIframe } from "@/shared/hooks/useIsInIframe.js";
import type { IframeMode } from "@/types.js";

export type IframeProviderOutput = {
  iframeRef: RefObject<HTMLIFrameElement> | null;
  logoutIframeRef: RefObject<HTMLIFrameElement> | null;
  setIframeIsVisible: Dispatch<SetStateAction<boolean>>;
  setIframeAborted: Dispatch<SetStateAction<boolean>>;
  iframeAborted: boolean;
  setLogoutIframeIsVisible: Dispatch<SetStateAction<boolean>>;
  iframeIsVisible: boolean;
  logoutIframeIsVisible: boolean;
  iframeMode: IframeMode;
  renderIframe: boolean;
};
const defaultIframe: IframeProviderOutput = {
  iframeRef: null,
  logoutIframeRef: null,
  setIframeIsVisible: () => {},
  setIframeAborted: () => {},
  setLogoutIframeIsVisible: () => {},
  iframeIsVisible: false,
  iframeAborted: false,
  logoutIframeIsVisible: false,
  iframeMode: "modal",
  renderIframe: false,
};

// Context for exposing Iframe specifically to the TokenProvider
const IframeContext = createContext<IframeProviderOutput>(defaultIframe);

type IframeContextType = {
  children: ReactNode;
  iframeMode?: IframeMode;
};

const IframeProvider = ({
  children,
  iframeMode = "modal",
}: IframeContextType) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const logoutIframeRef = useRef<HTMLIFrameElement>(null);
  const isInIframe = useIsInIframe();
  const [iframeIsVisible, setIframeIsVisible] = useState(false);
  const [iframeAborted, setIframeAborted] = useState(false);
  const [logoutIframeIsVisible, setLogoutIframeIsVisible] = useState(false);

  const renderIframe = iframeMode === "modal" && !isInIframe;
  return (
    <IframeContext.Provider
      value={{
        iframeRef,
        logoutIframeRef,
        setIframeIsVisible,
        iframeAborted,
        setIframeAborted,
        setLogoutIframeIsVisible,
        iframeIsVisible,
        logoutIframeIsVisible,
        iframeMode,
        renderIframe,
      }}
    >
      {children}
    </IframeContext.Provider>
  );
};

export type { IframeContextType };
export { IframeProvider, IframeContext };
