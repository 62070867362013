"use client";
import { useContext } from "react";
import { ClientTokenExchangeSessionContext } from "@/reactjs/providers/ClientTokenExchangeSessionProvider.js";

// TokenProvider will use this internal context to access session
const useClientTokenExchangeSession = () => {
  const context = useContext(ClientTokenExchangeSessionContext);
  if (!context) {
    throw new Error("useSession must be used within an SessionProvider");
  }
  return context;
};

export { useClientTokenExchangeSession };
