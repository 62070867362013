"use client";

import "./global.css";
import "@civic/ui/styles.css";
import localFont from "next/font/local";

import { Toaster } from "@civic/ui";
import { Providers } from "@/components/Providers";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";

const geistSans = localFont({
  src: "../fonts/Geist[wght].woff2",
  variable: "--font-geist-sans",
  weight: "100 900",
});

const geistMono = localFont({
  src: "../fonts/GeistMono[wght].woff2",
  variable: "--font-geist-mono",
  weight: "100 900",
});

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html className={`${geistSans.variable} ${geistMono.variable}`} lang="en">
      <GoogleTagManager gtmId="GTM-WKVC7QQJ" />
      {process.env.NEXT_PUBLIC_GA_ID && (
        <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_ID} />
      )}
      <body>
        <main className="via-cyan-indigo-50 relative h-screen w-screen bg-gradient-to-tl from-indigo-200 to-indigo-200">
          <Providers>{children}</Providers>
        </main>

        <Toaster />
      </body>
    </html>
  );
};

export default RootLayout;
