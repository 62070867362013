import { DEFAULT_AUTH_SERVER } from "@/constants.js";
import { getOauthEndpoints } from "@/lib/oauth.js";
import type { Endpoints } from "@/types.js";
import { useEffect, useState } from "react";

const useOAuthEndpoints = (oauthServer?: string) => {
  const [endpoints, setEndpoints] = useState<Endpoints | null>(null);
  useEffect(() => {
    getOauthEndpoints(oauthServer || DEFAULT_AUTH_SERVER).then(
      (retrievedEndpoints) => {
        setEndpoints(retrievedEndpoints);
      },
    );
  }, [oauthServer]);

  return endpoints;
};

export { useOAuthEndpoints };
