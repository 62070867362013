import { NextjsClientStorage } from "@/nextjs/cookies.js";
import { printVersion } from "@/shared/index.js";
printVersion();
import {
  getTokens as getSessionTokens,
  getUser as getSessionUser,
} from "@/shared/lib/session.js";
import type { OAuthTokens, User } from "@/types.js";

export { createCivicAuthPlugin, defaultAuthConfig } from "@/nextjs/config.js";

export const getUser = async (): Promise<User | null> => {
  const clientStorage = new NextjsClientStorage();
  return getSessionUser(clientStorage);
};
export const getTokens = async (): Promise<OAuthTokens | null> => {
  const clientStorage = new NextjsClientStorage();
  return getSessionTokens(clientStorage);
};
export { handler } from "@/nextjs/routeHandler.js";
export { NextjsCookieStorage, NextjsClientStorage } from "@/nextjs/cookies.js";
export type {
  AuthConfig,
  CookiesConfigObject,
  AuthConfigWithDefaults,
  DefinedAuthConfig,
} from "@/nextjs/config.js";
export {
  CivicNextAuthProvider as CivicAuthProvider,
  type NextCivicAuthProviderProps as AuthProviderProps,
} from "@/nextjs/providers/NextAuthProvider.js";
