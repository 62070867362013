"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation.js";
import { getWindowCookieValue } from "@/lib/cookies.js";
import type { EmptyObject, User } from "@/types.js";
import { OAuthTokens, UserStorage } from "@/shared/lib/types.js";
import { usePrevious } from "@/nextjs/hooks/usePrevious.js";
import { useTransition } from "react";
import { objectsAreEqual } from "@/lib/obj.js";

type UserAndTokenFromCookie = {
  [UserStorage.USER]: User | undefined;
  [OAuthTokens.ID_TOKEN]: string | undefined;
};
const getUserAndTokenFromCookie = (): UserAndTokenFromCookie =>
  getWindowCookieValue([
    {
      key: UserStorage.USER,
      window: globalThis.window,
      parseJson: true,
    },
    {
      key: OAuthTokens.ID_TOKEN,
      window: globalThis.window,
      parseJson: false,
    },
  ]) as UserAndTokenFromCookie;

export const useUserCookie = <T extends EmptyObject>() => {
  const [user, setUser] = useState<User<T> | null>(null);
  const [idToken, setIdToken] = useState<string | undefined>();
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const hasRunRef = useRef(false);
  const [isPending, startTransition] = useTransition();

  const router = useRouter();

  const prevUser = usePrevious(user);
  const prevToken = usePrevious(idToken);
  const prevPending = usePrevious(isPending);

  const fetchUser = useCallback(
    async (abortController?: AbortController): Promise<void> => {
      if (abortController?.signal.aborted) return;

      const response = getUserAndTokenFromCookie() || {};
      const userData = response[UserStorage.USER] as User<T>;
      const tokenData = response[OAuthTokens.ID_TOKEN];

      if (abortController?.signal.aborted) return;

      if (!objectsAreEqual(prevUser, userData)) {
        setUser(userData || null);
        setUserChanged(true);
      }
      if (tokenData !== prevToken) {
        setIdToken(tokenData);
      }
    },
    [prevToken, prevUser],
  );

  // call fetch immediately after a refresh
  useEffect(() => {
    setUserChanged(false);
    if (prevPending && !isPending) {
      fetchUser();
    }
  }, [prevPending, isPending, fetchUser]);

  useEffect(() => {
    let abortController = new AbortController();
    const cookieListener = () => {
      abortController = new AbortController();
      fetchUser(abortController);
    };

    document.addEventListener("visibilitychange", cookieListener);
    window.addEventListener("storage", cookieListener);
    window.addEventListener("focus", cookieListener);

    fetchUser(abortController);

    const intervalId = setInterval(cookieListener, 2000);

    return () => {
      abortController.abort();
      document.removeEventListener("visibilitychange", cookieListener);
      window.removeEventListener("storage", cookieListener);
      window.removeEventListener("focus", cookieListener);
      clearInterval(intervalId);
    };
  }, [fetchUser]);

  useEffect(() => {
    if (userChanged) {
      if (!hasRunRef.current) {
        hasRunRef.current = true;
        startTransition(() => {
          router.refresh();
        });
      }
    } else {
      hasRunRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChanged]);

  return { user, idToken, fetchUser, isPending };
};
