// Utility functions shared by auth server and client integrations
// Typically these functions should be used inside AuthenticationInitiator and AuthenticationResolver implementations
import type {
  AuthStorage,
  Endpoints,
  JWTPayload,
  OIDCTokenResponseBody,
  ParsedTokens,
} from "@/types.js";
import { OAuthTokens } from "./types.js";
import { OAuth2Client } from "oslo/oauth2";
import { getIssuerVariations, getOauthEndpoints } from "@/lib/oauth.js";
import * as jose from "jose";
import { withoutUndefined } from "@/utils.js";
import type { PKCEConsumer, PKCEProducer } from "@/services/types.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";

/**
 * Given a PKCE code verifier, derive the code challenge using SHA
 */
export async function deriveCodeChallenge(
  codeVerifier: string,
  method: "Plain" | "S256" = "S256",
): Promise<string> {
  if (method === "Plain") {
    console.warn("Using insecure plain code challenge method");
    return codeVerifier;
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export async function getEndpointsWithOverrides(
  oauthServer: string,
  endpointOverrides: Partial<Endpoints> = {},
): Promise<Endpoints> {
  const endpoints = await getOauthEndpoints(oauthServer);
  return {
    ...endpoints,
    ...endpointOverrides,
  };
}

export async function generateOauthLoginUrl(config: {
  clientId: string;
  scopes: string[];
  state: string;
  redirectUrl: string;
  oauthServer: string;
  nonce?: string;
  endpointOverrides?: Partial<Endpoints>;
  // used to get the PKCE challenge
  pkceConsumer: PKCEConsumer;
}): Promise<URL> {
  const endpoints = await getEndpointsWithOverrides(
    config.oauthServer,
    config.endpointOverrides,
  );
  const oauth2Client = buildOauth2Client(
    config.clientId,
    config.redirectUrl,
    endpoints,
  );
  const challenge = await config.pkceConsumer.getCodeChallenge();
  const oAuthUrl = await oauth2Client.createAuthorizationURL({
    state: config.state,
    scopes: config.scopes,
  });
  // The OAuth2 client supports PKCE, but does not allow passing in a code challenge from some other source
  // It only allows passing in a code verifier which it then hashes itself.
  oAuthUrl.searchParams.append("code_challenge", challenge);
  oAuthUrl.searchParams.append("code_challenge_method", "S256");
  if (config.nonce) {
    // nonce isn't supported by oslo, so we add it manually
    oAuthUrl.searchParams.append("nonce", config.nonce);
  }
  // Required by the auth server for offline_access scope
  oAuthUrl.searchParams.append("prompt", "consent");

  return oAuthUrl;
}

export async function generateOauthLogoutUrl(config: {
  clientId: string;
  redirectUrl: string;
  idToken: string;
  state: string;
  oauthServer: string;
  endpointOverrides?: Partial<Endpoints>;
}): Promise<URL> {
  const endpoints = await getEndpointsWithOverrides(
    config.oauthServer,
    config.endpointOverrides,
  );
  const endSessionUrl = new URL(endpoints.endsession);
  endSessionUrl.searchParams.append("client_id", config.clientId);
  endSessionUrl.searchParams.append("id_token_hint", config.idToken);
  endSessionUrl.searchParams.append("state", config.state);
  endSessionUrl.searchParams.append(
    "post_logout_redirect_uri",
    config.redirectUrl,
  );
  return endSessionUrl;
}

export function buildOauth2Client(
  clientId: string,
  redirectUri: string,
  endpoints: Endpoints,
): OAuth2Client {
  return new OAuth2Client(clientId, endpoints.auth, endpoints.token, {
    redirectURI: redirectUri,
  });
}

export async function exchangeTokens(
  code: string,
  state: string,
  pkceProducer: PKCEProducer,
  oauth2Client: OAuth2Client,
  oauthServer: string,
  endpoints: Endpoints,
) {
  const codeVerifier = await pkceProducer.getCodeVerifier();
  if (!codeVerifier) throw new Error("Code verifier not found in state");

  const tokens =
    await oauth2Client.validateAuthorizationCode<OIDCTokenResponseBody>(code, {
      codeVerifier,
    });

  // Validate relevant tokens
  try {
    await validateOauth2Tokens(tokens, endpoints, oauth2Client, oauthServer);
  } catch (error) {
    console.error("tokenExchange error", { error, tokens });
    throw new Error(
      `OIDC tokens validation failed: ${(error as Error).message}`,
    );
  }
  return tokens;
}

export async function storeTokens(
  storage: AuthStorage,
  tokens: OIDCTokenResponseBody,
) {
  // store tokens in storage ( TODO we should probably store them against the state to allow multiple logins )
  await storage.set(OAuthTokens.ID_TOKEN, tokens.id_token);
  await storage.set(OAuthTokens.ACCESS_TOKEN, tokens.access_token);
  if (tokens.refresh_token) {
    await storage.set(OAuthTokens.REFRESH_TOKEN, tokens.refresh_token);
  }
  if (tokens.expires_in) {
    await storage.set(OAuthTokens.EXPIRES_IN, tokens.expires_in.toString());
    await storage.set(OAuthTokens.TIMESTAMP, new Date().getTime().toString());
  }
}

export async function clearTokens(storage: AuthStorage) {
  const clearOAuthPromises = Object.values(OAuthTokens).map(async (key) => {
    await storage.set(key, "");
  });
  await Promise.all([...clearOAuthPromises]);
}

export async function clearUser(storage: AuthStorage) {
  const userSession = new GenericUserSession(storage);
  await userSession.set(null);
}

export async function retrieveTokens(
  storage: AuthStorage,
): Promise<OIDCTokenResponseBody | null> {
  const idToken = await storage.get(OAuthTokens.ID_TOKEN);
  const accessToken = await storage.get(OAuthTokens.ACCESS_TOKEN);
  const refreshToken = await storage.get(OAuthTokens.REFRESH_TOKEN);
  const expiresIn = await storage.get(OAuthTokens.EXPIRES_IN);
  const timestamp = await storage.get(OAuthTokens.TIMESTAMP);

  if (!idToken || !accessToken) return null;

  return {
    id_token: idToken,
    access_token: accessToken,
    refresh_token: refreshToken ?? undefined,
    expires_in: expiresIn ? parseInt(expiresIn, 10) : undefined, // Convert string to number
    timestamp: timestamp ? parseInt(timestamp, 10) : undefined, // Convert string to number
  };
}

export async function retrieveTokenExpiration(storage: AuthStorage) {
  return await storage.get(OAuthTokens.EXPIRES_IN);
}

export async function validateOauth2Tokens(
  tokens: OIDCTokenResponseBody,
  endpoints: Endpoints,
  oauth2Client: OAuth2Client,
  issuer: string,
): Promise<ParsedTokens> {
  const JWKS = jose.createRemoteJWKSet(new URL(endpoints.jwks));

  // validate the ID token
  const idTokenResponse = await jose.jwtVerify<JWTPayload>(
    tokens.id_token,
    JWKS,
    {
      issuer: getIssuerVariations(issuer),
      audience: oauth2Client.clientId,
    },
  );

  // validate the access token
  const accessTokenResponse = await jose.jwtVerify<JWTPayload>(
    tokens.access_token,
    JWKS,
    {
      issuer: getIssuerVariations(issuer),
    },
  );

  return withoutUndefined({
    id_token: idTokenResponse.payload,
    access_token: accessTokenResponse.payload,
    refresh_token: tokens.refresh_token,
  });
}
