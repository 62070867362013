"use client";

import { Button, Tabs, TabsList, TabsTrigger } from "@civic/ui";
import { GatewayStatus, useGateway } from "@civic/ethereum-gateway-react";
import { GatekeeperNetworkType } from "@/lib/pass";

interface PassProps {
  gatekeeperNetwork: GatekeeperNetworkType;
  setGatekeeperNetwork: (value: GatekeeperNetworkType) => void;
}

const Pass = ({ gatekeeperNetwork, setGatekeeperNetwork }: PassProps) => {
  const { gatewayStatus, requestGatewayToken } = useGateway();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-center gap-2">
        <Tabs
          defaultValue={gatekeeperNetwork}
          className="flex flex-col"
          onValueChange={(value) =>
            setGatekeeperNetwork(value as GatekeeperNetworkType)
          }
        >
          <TabsList className="overflow-x-auth flex justify-between overflow-y-hidden bg-transparent">
            <TabsTrigger
              value={"Captcha"}
              className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
            >
              CAPTCHA
            </TabsTrigger>
            <TabsTrigger
              value={"Uniqueness"}
              className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
            >
              Uniqueness
            </TabsTrigger>
            <TabsTrigger
              value={"Liveness"}
              className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
            >
              Liveness
            </TabsTrigger>
          </TabsList>
          <div className="flex flex-col justify-between px-3 py-1.5">
            {gatewayStatus === GatewayStatus.ACTIVE ? (
              <span className="text-center">
                You passed the Civic pass verification
              </span>
            ) : (
              <>
                <p className="text-center">
                  Try getting a Civic Pass verification:
                </p>
                <Button
                  onClick={() => {
                    if (!requestGatewayToken) {
                      throw new Error(
                        "Gateway token request function not available.",
                      );
                    }

                    requestGatewayToken();
                  }}
                >
                  {gatekeeperNetwork}
                </Button>
              </>
            )}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export { Pass };
