import type { AuthConfig } from "@/server/config.js";
import {
  getEndpointsWithOverrides,
  storeTokens,
  validateOauth2Tokens,
} from "@/shared/lib/util.js";
import type { AuthStorage, Endpoints, OIDCTokenResponseBody } from "@/types.js";
import { OAuth2Client } from "oslo/oauth2";
import { GenericAuthenticationRefresher } from "./GenericAuthenticationRefresher.js";

export class AuthenticationRefresherImpl extends GenericAuthenticationRefresher {
  private endpoints: Endpoints | undefined;
  private oauth2client: OAuth2Client | undefined;
  private constructor(
    authConfig: AuthConfig,
    storage: AuthStorage,
    private endpointOverrides?: Partial<Endpoints>,
  ) {
    super();
    this.authConfig = authConfig;
    this.storage = storage;
    this.init();
  }

  async init(): Promise<this> {
    if (!this.authConfig) throw new Error("No auth config available");
    // resolve oauth config
    this.endpoints = await getEndpointsWithOverrides(
      this.oauthServer,
      this.endpointOverrides,
    );
    this.oauth2client = new OAuth2Client(
      this.authConfig.clientId,
      this.endpoints.auth,
      this.endpoints.token,
      {
        redirectURI: this.authConfig.redirectUrl,
      },
    );

    return this;
  }

  static async build(
    authConfig: AuthConfig,
    storage: AuthStorage,
    endpointOverrides?: Partial<Endpoints>,
  ): Promise<AuthenticationRefresherImpl> {
    const refresher = new AuthenticationRefresherImpl(
      authConfig,
      storage,
      endpointOverrides,
    );
    await refresher.init();

    return refresher;
  }

  async refreshAccessToken(): Promise<OIDCTokenResponseBody> {
    if (!this.storage) throw new Error("No storage available");
    const refreshToken = await this.getRefreshToken();

    if (!this.oauth2client) this.init();
    const oauth2Client = this.oauth2client!;
    const tokenResponseBody =
      await oauth2Client.refreshAccessToken<OIDCTokenResponseBody>(
        refreshToken,
      );
    await validateOauth2Tokens(
      tokenResponseBody,
      this.endpoints!,
      oauth2Client,
      this.oauthServer,
    );

    await storeTokens(this.storage, tokenResponseBody);
    return tokenResponseBody;
  }
}
