import { retrieveTokens } from "@/shared/lib/util.js";
import { parseJWT } from "oslo/jwt";
import {
  tokenKeys,
  type AuthStorage,
  type OAuthTokens,
  type User,
} from "@/types.js";

// Function to omit keys from an object
const omitKeys = <K extends keyof T, T extends Record<string, unknown>>(
  keys: K[],
  obj: T,
): Omit<T, K> => {
  const result = { ...obj };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

export async function getUser(storage: AuthStorage): Promise<User | null> {
  const tokens = await retrieveTokens(storage);
  if (!tokens) return null;

  const parsedToken = parseJWT(tokens.id_token)?.payload as User;
  // Assumes all information is in the ID token
  // remove the token keys from the user object to stop it getting too large
  return parsedToken ? (omitKeys(tokenKeys, parsedToken) as User) : null;
}

export async function getTokens(
  storage: AuthStorage,
): Promise<OAuthTokens | null> {
  const storageData = await retrieveTokens(storage);
  if (!storageData) return null;

  return {
    idToken: storageData.id_token,
    accessToken: storageData.access_token,
    refreshToken: storageData.refresh_token,
  };
}
