import type { AuthConfig } from "@/server/config.js";
import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";
import { GenericAuthenticationRefresher } from "@/shared/lib/GenericAuthenticationRefresher.js";

export class NextClientAuthenticationRefresher extends GenericAuthenticationRefresher {
  private constructor(authConfig: AuthConfig, storage: AuthStorage) {
    super();
    this.authConfig = authConfig;
    this.storage = storage;
  }

  static async build(
    authConfig: AuthConfig,
    storage: AuthStorage,
  ): Promise<NextClientAuthenticationRefresher> {
    const refresher = new NextClientAuthenticationRefresher(
      authConfig,
      storage,
    );
    return refresher;
  }

  override async refreshAccessToken(): Promise<OIDCTokenResponseBody> {
    if (!this.authConfig) throw new Error("No auth config available");
    if (!this.authConfig.refreshUrl)
      throw new Error("No refresh URL available");

    const res = await fetch(`${this.authConfig?.refreshUrl}`);
    const json = await res.json();
    return json.tokens as OIDCTokenResponseBody;
  }
}
