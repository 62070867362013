import type { DisplayMode, Endpoints, OpenIdConfiguration } from "@/types.js";
import { v4 as uuid } from "uuid";

const getIssuerVariations = (issuer: string): string[] => {
  const issuerWithoutSlash = issuer.endsWith("/")
    ? issuer.slice(0, issuer.length - 1)
    : issuer;

  const issuerWithSlash = `${issuerWithoutSlash}/`;

  return [issuerWithoutSlash, issuerWithSlash];
};

const addSlashIfNeeded = (url: string): string =>
  url.endsWith("/") ? url : `${url}/`;

const cache: { [key: string]: Endpoints } = {};
const getOauthEndpoints = async (oauthServer: string): Promise<Endpoints> => {
  if (cache[oauthServer]) {
    return cache[oauthServer];
  }
  const openIdConfigResponse = await fetch(
    `${addSlashIfNeeded(oauthServer)}.well-known/openid-configuration`,
  );
  const openIdConfig =
    (await openIdConfigResponse.json()) as OpenIdConfiguration;
  const endpoints: Endpoints = {
    jwks: openIdConfig.jwks_uri,
    auth: openIdConfig.authorization_endpoint,
    token: openIdConfig.token_endpoint,
    userinfo: openIdConfig.userinfo_endpoint,
    endsession: openIdConfig.end_session_endpoint,
  };

  cache[oauthServer] = endpoints;
  return endpoints;
};

/**
 * creates a state string for the OAuth2 flow, encoding the display mode too for future use
 * @param {DisplayMode} displayMode
 * @returns {string}
 */
const generateState = (
  displayMode: DisplayMode,
  serverTokenExchange?: boolean,
): string => {
  const jsonString = JSON.stringify({
    uuid: uuid(),
    displayMode,
    ...(serverTokenExchange ? { serverTokenExchange } : {}),
  });
  return btoa(jsonString);
};

/**
 * parses the state string from the OAuth2 flow, decoding the display mode too
 * @param state
 * @param sessionDisplayMode
 * @returns { uuid: string, displayMode: DisplayMode }
 */
const displayModeFromState = (
  state: string,
  sessionDisplayMode: DisplayMode | undefined,
): DisplayMode | undefined => {
  try {
    const jsonString = atob(state);
    return JSON.parse(jsonString).displayMode;
  } catch (e) {
    console.error("Failed to parse displayMode from state:", state, e);
    return sessionDisplayMode;
  }
};

const serverTokenExchangeFromState = (state: string): boolean | undefined => {
  try {
    const jsonString = atob(state);
    return JSON.parse(jsonString).serverTokenExchange;
  } catch {
    console.error("Failed to parse serverTokenExchange from state:", state);
    return undefined;
  }
};

export {
  serverTokenExchangeFromState,
  getIssuerVariations,
  getOauthEndpoints,
  displayModeFromState,
  generateState,
};
