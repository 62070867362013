"use client";
import React from "react";
import { useUser } from "@/reactjs/hooks/useUser.js";
import { ButtonContentOrLoader } from "./ButtonContentOrLoader.js";

const SignOutButton = ({ className }: { className?: string }) => {
  const { signOut, authStatus, displayMode } = useUser();

  return (
    <button
      style={{
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        transition: "background-color 0.2s",
      }}
      className={className}
      onClick={() => signOut()}
    >
      <ButtonContentOrLoader authStatus={authStatus} displayMode={displayMode}>
        Sign Out
      </ButtonContentOrLoader>
    </button>
  );
};

export { SignOutButton };
