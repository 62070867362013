import type { ReactNode } from "react";
import React from "react";

const BlockDisplay = ({ children }: { children: ReactNode }) => {
  return (
    <div
      id="iframe-block-display-wrapper"
      style={{
        position: "relative",
        left: 0,
        top: 0,
        zIndex: 50,
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <div
        id="iframe-block-display"
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        {children}
      </div>
    </div>
  );
};
export { BlockDisplay };
