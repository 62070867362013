import { useEffect, useMemo, useState } from "react";
import { useCivicAuthConfig } from "./useCivicAuthConfig.js";
import type { SessionData } from "@/types.js";
import { AuthenticationRefresherImpl } from "../lib/AuthenticationRefresherImpl.js";
import { LocalStorageAdapter } from "@/browser/storage.js";

const useRefresh = (session: SessionData | null) => {
  const authConfig = useCivicAuthConfig();
  const storage = useMemo(() => new LocalStorageAdapter(), []);

  // setup token autorefresh
  const [refresher, setRefresher] = useState<
    AuthenticationRefresherImpl | undefined
  >(undefined);

  useEffect(() => {
    if (!authConfig) return;
    const abortController = new AbortController();
    const currentRefresher = refresher;

    AuthenticationRefresherImpl.build({ ...authConfig }, storage).then(
      (newRefresher) => {
        if (abortController.signal.aborted) return;

        currentRefresher?.clearAutorefresh();
        setRefresher(newRefresher);
      },
    );

    return () => {
      abortController.abort();
      currentRefresher?.clearAutorefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authConfig, storage]); // Only depend on what actually changes

  useEffect(() => {
    if (session?.authenticated) {
      refresher?.setupAutorefresh();
    } else {
      refresher?.clearAutorefresh();
    }

    return () => refresher?.clearAutorefresh();
  }, [refresher, session?.authenticated]);
};

export { useRefresh };
