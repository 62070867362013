"use client";
import type { SessionData } from "@/types.js";
import type { ReactNode } from "react";
import React, { createContext } from "react";

export type SessionProviderOutput = {
  data: SessionData | null;
  error: Error | null;
  isLoading: boolean;
};
const defaultSession: SessionProviderOutput = {
  data: {
    authenticated: false,
    idToken: undefined,
    accessToken: undefined,
    displayMode: "iframe",
  },
  error: null,
  isLoading: false,
};

// Context for exposing session specifically to the TokenProvider
const SessionContext = createContext<SessionProviderOutput>(defaultSession);

type SessionContextType = {
  children: ReactNode;
  data?: SessionData | null;
  error?: Error | null;
  isLoading: boolean;
};

const SessionProvider = ({ children, ...props }: SessionContextType) => {
  return (
    <SessionContext.Provider
      value={{
        ...props,
        data: props.data || null,
        error: props.error || null,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export type { SessionContextType };
export { SessionProvider, SessionContext };
