import { printVersion } from "../shared/index.js";
printVersion();
import {
  createCivicAuthPlugin as internalCivicAuthPlugin,
  NextjsCookieStorage,
} from "@civic/auth/nextjs";
import type { AuthConfig } from "@civic/auth/nextjs";
import type { NextConfig } from "next";
import { defaultAuthConfig, defaultServerSecure } from "./config.js";

export {
  NextjsCookieStorage,
  NextjsClientStorage,
  handler,
  defaultAuthConfig,
} from "@civic/auth/nextjs";
export type {
  AuthConfig,
  CookiesConfigObject,
  AuthConfigWithDefaults,
  DefinedAuthConfig,
} from "@civic/auth/nextjs";
export { CivicAuthProvider } from "./CivicAuthProvider.js";
import type { ServerUser } from "../shared/serverTypes.js";
import {
  createWallet as createWalletInternal,
  getWallets as getWalletsInternal,
  getUser as getUserShared,
  type UserWithIdToken,
} from "../shared/serverFunctions.js";
import type {
  CivicApiClientConfig,
  GetWalletsResponse,
  UnknownObject,
} from "../types.js";
import { getTokens } from "@civic/auth/server";
export { getTokens };

export const getUser = async <T extends UnknownObject>(
  config: CivicApiClientConfig = {},
): Promise<ServerUser<T> | null> => {
  return getUserShared<T>(config, new NextjsCookieStorage());
};
export const createWallet = async (config: CivicApiClientConfig = {}) => {
  return createWalletInternal(config, new NextjsCookieStorage());
};
export const getWallets = async (
  config: CivicApiClientConfig = {},
): Promise<GetWalletsResponse | null> => {
  const nextStorage = new NextjsCookieStorage();
  const user = await getUser(config);
  const tokens = await getTokens(nextStorage);
  if (!user || !tokens) {
    return null;
  }
  const userWithIdToken = {
    ...user,
    idToken: tokens?.idToken,
  } as UserWithIdToken;
  return getWalletsInternal(userWithIdToken, config);
};
/**
 * We override the httpOnly flag of the id_token cookie to false,
 * so that turnkey can access it on the frontend.
 */
export const createCivicAuthPlugin = (
  authConfig: AuthConfig = {},
): ((nextConfig?: NextConfig) => NextConfig) => {
  const useAuthConfig = { ...authConfig, clientId: authConfig.clientId! };
  if (!useAuthConfig.cookies?.tokens.id_token) {
    useAuthConfig.cookies = {
      ...(defaultAuthConfig.cookies || {}),
      ...(useAuthConfig.cookies || {}),
      tokens: {
        ...(defaultAuthConfig.cookies?.tokens || {}),
        ...(useAuthConfig.cookies?.tokens || {}),
        ["id_token"]: {
          secure: defaultServerSecure,
          httpOnly: false,
          sameSite: "strict",
          path: "/",
        },
      },
    };
  }
  return internalCivicAuthPlugin(useAuthConfig);
};
