import { DEFAULT_AUTH_SERVER } from "@/constants.js";
import type { AuthConfig } from "@/server/config.js";
import type { AuthenticationRefresher } from "@/services/types.js";
import { retrieveTokenExpiration, retrieveTokens } from "@/shared/lib/util.js";
import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";

export abstract class GenericAuthenticationRefresher
  implements AuthenticationRefresher
{
  private refreshTimeout: NodeJS.Timeout | undefined;
  protected authConfig: AuthConfig | undefined;
  protected storage: AuthStorage | undefined;

  get oauthServer(): string {
    return this.authConfig?.oauthServer || DEFAULT_AUTH_SERVER;
  }

  abstract refreshAccessToken(
    refreshToken?: string,
  ): Promise<OIDCTokenResponseBody>;

  async getRefreshToken(): Promise<string> {
    if (!this.storage) throw new Error("No storage available");

    const tokens = await retrieveTokens(this.storage);
    if (!tokens?.refresh_token) throw new Error("No refresh token available");
    return tokens.refresh_token;
  }

  async refreshTokens() {
    return this.refreshAccessToken();
  }

  private async handleRefresh() {
    try {
      await this.refreshTokens();
      await this.setupAutorefresh(); // Reset the timeout after successful refresh
    } catch (error) {
      console.error("Failed to refresh tokens:", error);
    }
  }

  async setupAutorefresh() {
    if (!this.storage) throw new Error("No storage available");
    // Clear any existing timeout
    this.clearAutorefresh();

    // get expires_in
    const expiration = await retrieveTokenExpiration(this.storage);
    const expires_in = Number(expiration) || 60;

    // Calculate time until expiry (subtract 30 seconds as buffer)
    const bufferTimeMs = 30 * 1000; // 30 seconds in milliseconds
    const expiresInMs = expires_in * 1000; // Convert to milliseconds
    const refreshTimeMs = Math.max(0, expiresInMs - bufferTimeMs);

    this.refreshTimeout = setTimeout(() => {
      this.handleRefresh();
    }, refreshTimeMs);
  }

  clearAutorefresh() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }
}
