/**
 * Frontend analytics event emitter for the civic auth wallet SDK
 *
 * Requests made directly to the turnkey endpoint are not tracked by our backend, so we need to emit events directly from the frontend.
 */

type CivicAuthWeb3EventType =
  | "signMessage"
  | "signTransaction"
  | "signTypedData";
type CivicAuthWeb3ErrorEventType = `${CivicAuthWeb3EventType}Error`;
const toErrorType = <T extends CivicAuthWeb3EventType>(
  eventType: T,
): `${T}Error` => `${eventType}Error`;
const errorToMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return JSON.stringify(error);
};

type CivicAuthWeb3Event = {
  eventType: CivicAuthWeb3EventType | CivicAuthWeb3ErrorEventType;
  error?: string;
  parameters: unknown[];
};
type AnalyticsConfig = {
  endpoint?: string;
  flowId?: string;
};

const randomFlowId = () => Math.random().toString(36).substring(7);
const DEFAULT_ANALYTICS_ENDPOINT = "https://api.civic.com/analytics/events";

function bigIntReplacer<T>(_key: string, value: T): T | string {
  if (typeof value === "bigint") return value.toString() + "n";
  return value;
}

// POST to the internal civic analytics endpoint
const emitToAnalyticsEndpoint = async (
  event: CivicAuthWeb3Event,
  config: AnalyticsConfig,
): Promise<Response> => {
  const endpoint = config.endpoint ?? DEFAULT_ANALYTICS_ENDPOINT;
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-civic-flow-id": config.flowId ?? randomFlowId(),
    },
    body: JSON.stringify(
      {
        source: "civic-auth-web3-sdk",
        target: "turnkey",
        ...event,
      },
      bigIntReplacer,
    ),
  });
};

// Given a function, return the same function wrapped with an analytics event that is emitted after the function is called successfully
const wrapFnWithAnalytics =
  <Args extends unknown[], R>(
    fn: (...args: Args) => Promise<R>,
    emitter: AnalyticsEmitter,
    eventType: CivicAuthWeb3EventType,
  ): ((...args: Args) => Promise<R>) =>
  async (...args: Args): Promise<R> => {
    let event: CivicAuthWeb3Event | undefined = undefined;
    try {
      const result = await fn(...args);
      event = {
        eventType,
        parameters: args,
      };

      return result;
    } catch (error) {
      console.error("Failed to emit event to analytics endpoint", error);

      event = {
        eventType: toErrorType(eventType),
        error: errorToMessage(error),
        parameters: args,
      };
      throw error;
    } finally {
      await emitter.emitEvent(event as CivicAuthWeb3Event);
    }
  };

export class AnalyticsEmitter {
  constructor(private config: AnalyticsConfig) {}

  async emitEvent(event: CivicAuthWeb3Event): Promise<void> {
    try {
      await emitToAnalyticsEndpoint(event, this.config);
    } catch (error) {
      console.error("Failed to emit event to analytics endpoint", error);
    }
  }

  wrapFn<Args extends unknown[], R>(
    fn: (...args: Args) => Promise<R>,
    eventType: CivicAuthWeb3EventType,
  ): (...args: Args) => Promise<R> {
    return wrapFnWithAnalytics(fn, this, eventType);
  }
}
