import type { AuthConfigWithDefaults } from "@civic/auth/nextjs";
import { CodeVerifier, OAuthTokens } from "../types.js";

// Remove environment-dependent logic and set a safe default
export const defaultServerSecure = true;

const DEFAULT_AUTH_SERVER = "https://auth.civic.com/oauth";

/**
 * Default configuration values that will be used if not overridden
 */
export const defaultAuthConfig: Omit<AuthConfigWithDefaults, "clientId"> = {
  oauthServer: DEFAULT_AUTH_SERVER,
  callbackUrl: "/api/auth/callback",
  refreshUrl: "/api/auth/refresh",
  challengeUrl: "/api/auth/challenge",
  logoutUrl: "/api/auth/logout",
  logoutCallbackUrl: "/api/auth/logoutcallback",
  loginUrl: "/",
  include: ["/*"],
  exclude: [],
  cookies: {
    tokens: {
      [OAuthTokens.ID_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.ACCESS_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.REFRESH_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.EXPIRES_IN]: {
        secure: defaultServerSecure,
        httpOnly: false,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.TIMESTAMP]: {
        secure: defaultServerSecure,
        httpOnly: false,
        sameSite: "strict",
        path: "/",
      },
      [CodeVerifier.COOKIE_NAME]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [CodeVerifier.APP_URL]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
    },
    user: {
      secure: defaultServerSecure,
      httpOnly: false,
      sameSite: "strict",
      path: "/",
      maxAge: 60 * 60, // 1 hour
    },
  },
};
