const isWindowInIframe = (window: Window): boolean => {
  if (typeof window !== "undefined") {
    // use the window width to determine if we're in an iframe or not
    try {
      if (window?.frameElement?.id === "civic-auth-iframe") {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_e) {
      // If we get an error, we're not in an iframe
      return false;
    }
  }
  return false;
};

const removeParamsWithoutReload = (paramsToRemove: string[]) => {
  const url = new URL(window.location.href);
  paramsToRemove.forEach((param: string) => {
    url.searchParams.delete(param);
  });
  try {
    window.history.replaceState({}, "", url);
    window.dispatchEvent(new Event("popstate"));
  } catch (error) {
    console.warn("window.history.replaceState failed", error);
  }
};

export { isWindowInIframe, removeParamsWithoutReload };
