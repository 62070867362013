import { useEffect, useState } from "react";

const useCurrentUrl = () => {
  const [currentUrl, setCurrentUrl] = useState<string | undefined>();
  // update the current url when the url changes
  useEffect(() => {
    const handleUrlChange = () => {
      setCurrentUrl(window.location.href);
    };
    window.addEventListener("popstate", handleUrlChange);
    window.addEventListener("pushstate", handleUrlChange);
    window.addEventListener("replacestate", handleUrlChange);

    handleUrlChange();

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("popstate", handleUrlChange);
        window.removeEventListener("pushstate", handleUrlChange);
        window.removeEventListener("replacestate", handleUrlChange);
      }
    };
  }, []);

  return currentUrl;
};
export { useCurrentUrl };
