"use client";
import React from "react";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import type { AuthStatus, DisplayMode } from "@/types.js";
import { shouldShowLoader } from "./utils.js";

export const ButtonContentOrLoader = ({
  authStatus,
  displayMode,
  children,
}: {
  authStatus: AuthStatus;
  displayMode: DisplayMode;
  children: React.ReactNode;
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          visibility: shouldShowLoader(authStatus, displayMode)
            ? "hidden"
            : "visible",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </span>
      {shouldShowLoader(authStatus, displayMode) && (
        <span
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <LoadingIcon width="1.5em" height="1.5em" />
        </span>
      )}
    </div>
  );
};
