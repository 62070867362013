import type {
  ApiClientConfig,
  CreateSubOrganisationResponse,
  GetWalletsResponse,
} from "../../types.js";
import type { UserWithIdToken } from "../../shared/serverFunctions.js";

export class CivicTurnkeyApiClient {
  constructor(
    readonly user: UserWithIdToken,
    readonly config: ApiClientConfig,
  ) {}

  async createWallet(): Promise<CreateSubOrganisationResponse> {
    const response = await fetch(this.config.endpoints.wallet + "/wallet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.user.idToken}`,
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error(`Failed to create wallet: ${response.statusText}`);
    }

    const walletResponse: { result: CreateSubOrganisationResponse } =
      await response.json();

    return walletResponse.result;
  }

  async getWallets(): Promise<GetWalletsResponse | null> {
    const url = this.config.endpoints.wallet + "/wallet";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.user.idToken}`,
      },
    });
    if (!response.ok) {
      console.error(await response.json());
      throw new Error(`Failed to get wallets: ${response.statusText}`);
    }

    const walletsResponse: { result: { wallets: GetWalletsResponse } | null } =
      await response.json();

    return walletsResponse.result?.wallets ?? null;
  }
}
