import type { ApiClientConfig, TurnkeyConfig } from "./types.js";

export const DEFAULT_TURNKEY_CONFIG: TurnkeyConfig = {
  apiBaseUrl: "https://api.turnkey.com",
  defaultOrganizationId: "79d0f7c9-cb9c-467c-b9ed-2a7ec24b9e15",
  serverSignUrl: "https://api.civic.com/turnkey/proxy",
  rpId: "localhost",
  iframeUrl: "https://auth.turnkey.com",
};
// Default configuration that can be used in the implementation
export const DEFAULT_CONFIG: ApiClientConfig = {
  endpoints: {
    wallet: "https://api.civic.com/turnkey",
    analytics: "https://api.civic.com/analytics/events",
    rpcs: {},
  },
  turnkey: DEFAULT_TURNKEY_CONFIG,
};
