import { BrowserAuthenticationInitiator } from "@/services/AuthenticationService.js";
import { BrowserPublicClientPKCEProducer } from "@/services/PKCE.js";
import { useCivicAuthConfig } from "@/shared/hooks/useCivicAuthConfig.js";
import { AuthStatus, type DisplayMode } from "@/types.js";
import { useIframe } from "@/shared/hooks/useIframe.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PopupError, type PKCEConsumer } from "@/services/types.js";
import { useSession } from "./useSession.js";
import { LocalStorageAdapter } from "@/browser/storage.js";
import { clearTokens, clearUser } from "../lib/util.js";

type SignInProps = {
  pkceConsumer?: PKCEConsumer;
  preSignOut?: () => Promise<void>;
  postSignOut?: () => Promise<void>;
  displayMode: DisplayMode;
};
const useSignIn = ({
  pkceConsumer,
  preSignOut,
  postSignOut,
  displayMode,
}: SignInProps) => {
  const civicAuthConfig = useCivicAuthConfig();
  const {
    iframeRef,
    logoutIframeRef,
    setIframeIsVisible,
    iframeIsVisible,
    setLogoutIframeIsVisible,
    iframeAborted,
    setIframeAborted,
  } = useIframe();
  const { data: session } = useSession();
  const [authStatus, setAuthStatus] = useState(AuthStatus.UNAUTHENTICATED);

  const authInitiator = useMemo(() => {
    if (!civicAuthConfig) {
      return null;
    }
    const {
      clientId,
      redirectUrl,
      logoutUrl,
      logoutRedirectUrl,
      nonce,
      oauthServer,
      endpoints,
      scopes,
    } = civicAuthConfig;
    return new BrowserAuthenticationInitiator({
      pkceConsumer: pkceConsumer || new BrowserPublicClientPKCEProducer(), // generate and retrieve the challenge client-side
      clientId,
      redirectUrl,
      logoutUrl,
      logoutRedirectUrl,
      scopes,
      displayMode,
      oauthServer,
      endpointOverrides: endpoints,
      nonce,
    });
  }, [civicAuthConfig, displayMode, pkceConsumer]);

  useEffect(() => {
    return () => {
      if (authInitiator) {
        authInitiator.cleanup();
      }
    };
  }, [authInitiator]);

  // This effect is used to clear the tokens and user when the user signs out after a redirect
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const state = params.get("state");
    const localStorage = new LocalStorageAdapter();
    localStorage.get("logout_state").then((storedLogoutState) => {
      if (state && state === storedLogoutState) {
        // Clear storage
        clearTokens(localStorage);
        clearUser(localStorage);
        LocalStorageAdapter.emitter.emit("signOut");

        // Clean up storage and URL
        sessionStorage.removeItem("logout_state");
        const cleanUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, cleanUrl);
      }
    });
  }, []);

  const signIn = useCallback(async (): Promise<void> => {
    if (!authInitiator) return;

    setAuthStatus(AuthStatus.AUTHENTICATING);
    authInitiator.setDisplayMode(displayMode);
    if (displayMode === "iframe") {
      setIframeIsVisible(true);
    }
    const useIframeRef = iframeRef?.current || null;
    await authInitiator.signIn(useIframeRef).catch((error) => {
      setAuthStatus(AuthStatus.ERROR);
      console.error("signIn error", {
        error,
        isPopupError: error instanceof PopupError,
      });
      // if we've tried to open a popup and it has failed, then fallback to redirect mode
      if (error instanceof PopupError) {
        setIframeIsVisible(false); // hide the iframe
        authInitiator.cleanup(); // clear any event listeners from before
        authInitiator.setDisplayMode("redirect"); // switch to redirect mode
        authInitiator.signIn(useIframeRef); // retry the sign in
      }
    });
  }, [authInitiator, displayMode, iframeRef, setIframeIsVisible]);

  const signOut = useCallback(async () => {
    const idToken = session?.idToken;
    if (!authInitiator) return;

    setAuthStatus(AuthStatus.SIGNING_OUT);
    if (displayMode === "iframe") {
      setIframeIsVisible(false);
      setLogoutIframeIsVisible(true);
    }

    try {
      await preSignOut?.();

      const useIframeRef = logoutIframeRef?.current || null;
      await authInitiator.signOut(idToken, useIframeRef).catch((error) => {
        setAuthStatus(AuthStatus.ERROR);
        console.error("signOut error", {
          error,
          isPopupError: error instanceof PopupError,
        });

        // Same popup fallback as signIn
        if (error instanceof PopupError) {
          setLogoutIframeIsVisible(false);
          authInitiator.cleanup();
          authInitiator.setDisplayMode("redirect");
          authInitiator.signOut(idToken, useIframeRef);
        }
      });
    } catch (error) {
      console.error("Signout error:", error);
    }
  }, [
    session?.idToken,
    authInitiator,
    displayMode,
    setLogoutIframeIsVisible,
    setIframeIsVisible,
    preSignOut,
    logoutIframeRef,
  ]);

  useEffect(() => {
    if (session?.authenticated) {
      setAuthStatus(AuthStatus.AUTHENTICATED);
    }
    if (displayMode === "iframe" && iframeAborted) {
      setIframeAborted(false);
      if (!session?.authenticated) {
        setAuthStatus(AuthStatus.UNAUTHENTICATED);
      }
    }
  }, [displayMode, iframeAborted, session?.authenticated, setIframeAborted]);

  // handle logout finished
  useEffect(() => {
    if (authStatus === AuthStatus.SIGNING_OUT && !session?.authenticated) {
      setAuthStatus(AuthStatus.UNAUTHENTICATED);
      postSignOut?.().then(() => {
        setLogoutIframeIsVisible(false);
      });
      return;
    }
  }, [
    session,
    postSignOut,
    setLogoutIframeIsVisible,
    displayMode,
    iframeIsVisible,
    authStatus,
  ]);

  return {
    signIn,
    signOut,
    authStatus,
    displayMode,
  };
};
export { useSignIn };
