"use client";
import {
  Card,
  CardContent,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@civic/ui";
import { Balance } from "./balance";
import { Address } from "./address";
import { Sign } from "./sign";
import { Send } from "./send";
import { ChainSelector } from "./chain-selector";
import {
  CivicAuthIframeContainer,
  useUser,
  useAutoConnect,
} from "@civic/auth-web3/react";

import { useAccount, useBalance } from "wagmi";
import { Airdrop } from "@/app/_components/airdrop";
import { Loader2 } from "lucide-react";
import { useCallback, useState } from "react";
import { formatBalance } from "@/lib/utils";
import { Pass } from "./pass";
import { GatewayProvider } from "@civic/ethereum-gateway-react";
import { useWallet } from "@/hooks/useWallet";
import { SignTypedData } from "@/app/_components/signTypedData";
import { GATEKEEPER_NETWORKS, GatekeeperNetworkType } from "@/lib/pass";

function Web3U({
  walletCreationInProgress,
}: {
  walletCreationInProgress?: boolean;
}) {
  const { isConnected, address } = useAccount();
  const balance = useBalance({ address });
  const userContext = useUser();
  const { isLoading } = userContext;
  const useIsLoading = isLoading || walletCreationInProgress;
  const wallet = useWallet();
  const [gatekeeperNetwork, setGatekeeperNetwork] =
    useState<GatekeeperNetworkType>("Captcha");

  // on transaction update, retry refetching the balance until it updates
  const onComplete = useCallback(async () => {
    const balanceBefore = formatBalance(balance?.data?.value);
    let retries = 5;

    const retryUntilUpdated = async () => {
      const newBalance = await balance?.refetch();
      if (
        formatBalance(newBalance?.data?.value) === balanceBefore &&
        retries > 0
      ) {
        retries -= 1;
        setTimeout(retryUntilUpdated, 1000);
      }
    };

    await retryUntilUpdated();
  }, [balance]);

  return (
    <>
      {!isConnected || useIsLoading ? (
        <div className="pointer-events-auto absolute inset-0 z-20 flex flex-col items-center justify-center rounded-3xl bg-white/50">
          <div>Connecting wallet...</div>
          <Loader2 className="mt-2 h-8 w-8 animate-spin" />
        </div>
      ) : null}
      <div
        className={`${!isConnected ? "pointer-events-none opacity-50" : ""} flex flex-col gap-4`}
      >
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
          <div>
            <ChainSelector />
          </div>

          <div className="flex flex-row">
            <Balance value={balance?.data?.value} />
            <Airdrop onComplete={onComplete} />
          </div>
        </div>

        <div>
          <Address />
        </div>

        <div>
          <Tabs defaultValue="sign" className="flex flex-col">
            <TabsList className="overflow-x-auth flex justify-between overflow-y-hidden bg-transparent">
              <TabsTrigger
                value="sign"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Sign Message
              </TabsTrigger>
              <TabsTrigger
                value="send"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Send ETH
              </TabsTrigger>
              <TabsTrigger
                value="pass"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Civic Pass
              </TabsTrigger>
            </TabsList>
            <TabsContent value="sign" className="px-3 py-1.5">
              <Sign />
            </TabsContent>
            <TabsContent value="signTypedData" className="px-3 py-1.5">
              <SignTypedData />
            </TabsContent>
            <TabsContent value="send" className="px-3 py-1.5">
              <Send onComplete={onComplete} />
            </TabsContent>
            <TabsContent value="pass" className="px-3 py-1.5">
              <GatewayProvider
                gatekeeperNetwork={GATEKEEPER_NETWORKS[gatekeeperNetwork]}
                wallet={wallet}
                options={{
                  hideWalletPrompts: true,
                }}
              >
                <Pass
                  gatekeeperNetwork={gatekeeperNetwork}
                  setGatekeeperNetwork={setGatekeeperNetwork}
                />
              </GatewayProvider>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}

function Web3Zone() {
  const { user, isLoading } = useUser();
  const { walletCreationInProgress } = useAutoConnect();

  if (!isLoading && !user)
    return (
      <Card className="z-10 w-full sm:max-w-96">
        <CardContent className="relative flex items-center justify-center overflow-hidden p-4">
          <CivicAuthIframeContainer />
        </CardContent>
      </Card>
    );

  return (
    <Card className="z-10 w-full sm:w-[32rem]">
      <CardContent className="relative p-4 sm:p-10">
        <Web3U walletCreationInProgress={walletCreationInProgress} />
      </CardContent>
    </Card>
  );
}
export { Web3Zone };
