import type { TokenResponseBody } from "oslo/oauth2";
import type { JWT } from "oslo/jwt";

type UnknownObject = Record<string, unknown>;
type EmptyObject = Record<string, never>;

export enum AuthStatus {
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
  AUTHENTICATING = "authenticating",
  ERROR = "error",
  SIGNING_OUT = "signing_out",
}
// Display modes for the auth flow
type DisplayMode = "iframe" | "redirect" | "new_tab" | "custom_tab";

// Combined Auth and Session Service
interface AuthSessionService {
  // TODO DK NOTES: Should be in BrowserAuthSessionService, not relevant on backend
  loadAuthorizationUrl(
    authorizationURL: string,
    displayMode: DisplayMode,
  ): void;
  // TODO DK NOTES: overrideDisplayMode parameter not appropriate here - also - do we need both this and the above in the interface?
  getAuthorizationUrl(
    scopes: string[],
    overrideDisplayMode: DisplayMode,
    nonce?: string,
  ): Promise<string>;
  // TODO DK NOTES: display mode should be in browser version only. Also, do we need this and the above two in the top-level interface?
  signIn(
    displayMode: DisplayMode,
    scopes: string[],
    nonce?: string,
  ): Promise<void>;
  // TODO DK NOTES: Input should be an auth code - do not assume it comes via an url
  tokenExchange(responseUrl: string): Promise<SessionData>;
  // TODO DK NOTES: Should be async for flexibility
  getSessionData(): SessionData;
  // TODO DK NOTES: Should be async for flexibility
  updateSessionData(data: SessionData): void;
  getUserInfoService(): Promise<UserInfoService>;
}

// Token Service
interface TokenService {
  exchangeCodeForTokens(authCode: string): Promise<Tokens>;
  validateIdToken(idToken: string, nonce: string): boolean;
  refreshAccessToken(refreshToken: string): Promise<Tokens>;
}

// User Info Service
interface UserInfoService {
  getUserInfo<T extends UnknownObject>(
    accessToken: string,
    idToken: string | null,
  ): Promise<User<T> | null>;
}

// Resource Service
interface ResourceService {
  getProtectedResource(accessToken: string): Promise<unknown>;
}

// Auth Request (for internal use in AuthSessionService)
type AuthRequest = {
  clientId: string;
  redirectUri: string;
  state: string;
  nonce: string;
  scope: string;
};

type Endpoints = {
  jwks: string;
  auth: string;
  token: string;
  userinfo: string;
  challenge?: string;
  endsession: string;
};

type Config = {
  oauthServer: string;
  endpoints?: Endpoints;
};

type SessionData = {
  authenticated: boolean; // TODO can this be inferred from the presence of the tokens?
  state?: string;
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  timestamp?: number;
  expiresIn?: number;
  codeVerifier?: string;
  displayMode?: DisplayMode;
  openerUrl?: string;
};

type OIDCTokenResponseBody = TokenResponseBody & {
  id_token: string;
  timestamp?: number;
};

type ParsedTokens = {
  id_token: JWTPayload;
  access_token: JWTPayload;
  refresh_token?: string;
};

// The format we expose to the frontend via hooks
type ForwardedTokens = Record<
  string,
  {
    idToken?: string;
    accessToken?: string;
    refreshToken?: string;
  }
>;

// The format in the JWT payload
type ForwardedTokensJWT = Record<
  string,
  {
    id_token?: string;
    access_token?: string;
    refresh_token?: string;
    scope?: string;
  }
>;

type JWTPayload = JWT["payload"] & {
  iss: string;
  aud: string;
  sub: string;
  iat: number;
  exp: number;
};

type IdTokenPayload = JWTPayload & {
  forwardedTokens?: ForwardedTokensJWT;
  email?: string;
  name?: string;
  picture?: string;
  nonce: string;
  at_hash: string;
};

type IdToken = Omit<JWT, "payload"> & {
  payload: IdTokenPayload;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tokenKeys = ["idToken", "accessToken", "refreshToken", "forwardedTokens"];

export type OAuthTokens = {
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
};
// Derive the Tokens type from the array
type Tokens = {
  [K in (typeof tokenKeys)[number]]: K extends "forwardedTokens"
    ? ForwardedTokens
    : string;
};

// Base user interface
type BaseUser = {
  id: string;
  email?: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
  updated_at?: Date;
};

type User<T extends UnknownObject = EmptyObject> = BaseUser & T;

type OpenIdConfiguration = {
  authorization_endpoint: string;
  claims_parameter_supported: boolean;
  claims_supported: string[];
  code_challenge_methods_supported: string[];
  end_session_endpoint: string;
  grant_types_supported: string[];
  issuer: string;
  jwks_uri: string;
  authorization_response_iss_parameter_supported: boolean;
  response_modes_supported: string[];
  response_types_supported: string[];
  scopes_supported: string[];
  subject_types_supported: string[];
  token_endpoint_auth_methods_supported: string[];
  token_endpoint_auth_signing_alg_values_supported: string[];
  token_endpoint: string;
  id_token_signing_alg_values_supported: string[];
  pushed_authorization_request_endpoint: string;
  request_parameter_supported: boolean;
  request_uri_parameter_supported: boolean;
  userinfo_endpoint: string;
  claim_types_supported: string[];
};

type LoginPostMessage = {
  source: string;
  type: string;
  clientId: string;
  data: {
    url: string;
  };
};

export type IframeAuthMessage = {
  source: "civicloginApp";
  type: "auth_error" | "auth_error_try_again";
  clientId: string;
  data: {
    url?: string;
    error?: string;
  };
};

export type {
  LoginPostMessage,
  AuthSessionService,
  TokenService,
  UserInfoService,
  ResourceService,
  AuthRequest,
  Tokens,
  Endpoints,
  Config,
  SessionData,
  OIDCTokenResponseBody,
  ParsedTokens,
  BaseUser,
  User,
  DisplayMode,
  UnknownObject,
  EmptyObject,
  ForwardedTokens,
  ForwardedTokensJWT,
  JWTPayload,
  IdTokenPayload,
  IdToken,
  OpenIdConfiguration,
};
export { tokenKeys };
export interface AuthStorage {
  get(key: string): Promise<string | null>;
  set(key: string, value: string): Promise<void>;
}

export type IframeMode = "embedded" | "modal";
