"use client";
import { DEFAULT_AUTH_SERVER, DEFAULT_SCOPES } from "@/constants.js";
import React, { createContext, useMemo, type ReactNode } from "react";
import { useOAuthEndpoints } from "@/shared/hooks/useOAuthEndpoints.js";
import { useCurrentUrl } from "@/shared/hooks/useCurrentUrl.js";
import type { CivicAuthConfig } from "../lib/types.js";

const defaultConfig: CivicAuthConfig = null;

type CivicAuthConfigContextType = {
  children: ReactNode;
  oauthServer?: string;
  clientId: string;
  scopes?: string[];
  redirectUrl?: string;
  logoutRedirectUrl?: string;
  nonce?: string;
  challengeUrl?: string;
  refreshUrl?: string;
  logoutUrl?: string;
  logoutCallbackUrl?: string;
};
// Context for exposing Config specifically to the TokenProvider
const CivicAuthConfigContext = createContext<CivicAuthConfig>(defaultConfig);

const CivicAuthConfigProvider = ({
  children,
  oauthServer,
  clientId,
  redirectUrl: inputRedirectUrl,
  nonce,
  challengeUrl,
  refreshUrl,
  logoutUrl,
  scopes,
  logoutRedirectUrl: inputLogoutRedirectUrl,
}: CivicAuthConfigContextType) => {
  const currentUrl = useCurrentUrl();

  const redirectUrl = useMemo(() => {
    const useUrl = inputRedirectUrl || currentUrl;
    if (useUrl) {
      return `${useUrl.split("?")[0]}`;
    }
    return "";
  }, [currentUrl, inputRedirectUrl]);
  const endpoints = useOAuthEndpoints(oauthServer);

  const logoutRedirectUrl = useMemo(() => {
    const useUrl = inputLogoutRedirectUrl || currentUrl;
    if (useUrl) {
      return `${useUrl.split("?")[0]}`;
    }
    return "";
  }, [currentUrl, inputLogoutRedirectUrl]);

  const value = useMemo(
    () =>
      endpoints
        ? {
            clientId,
            redirectUrl,
            oauthServer: oauthServer || DEFAULT_AUTH_SERVER,
            endpoints,
            nonce,
            challengeUrl,
            refreshUrl,
            logoutUrl,
            scopes: scopes || DEFAULT_SCOPES,
            logoutRedirectUrl,
          }
        : null,
    [
      clientId,
      redirectUrl,
      oauthServer,
      endpoints,
      nonce,
      challengeUrl,
      refreshUrl,
      logoutUrl,
      scopes,
      logoutRedirectUrl,
    ],
  );
  return (
    <CivicAuthConfigContext.Provider value={value}>
      {children}
    </CivicAuthConfigContext.Provider>
  );
};

export { CivicAuthConfigProvider, CivicAuthConfigContext };
