"use client";

import { FC, PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { embeddedWallet } from "@civic/auth-web3";
import { CivicAuthProvider } from "@civic/auth-web3/nextjs";
import { polygonAmoy, baseSepolia } from "viem/chains";
import { Chain, http } from "viem";
import { createConfig, WagmiProvider } from "wagmi";

const queryClient = new QueryClient();

// Configure chains and RPC URLs.
const supportedChains = [baseSepolia, polygonAmoy] as [Chain, ...Chain[]];

const wagmiConfig = createConfig({
  chains: supportedChains,
  transports: {
    [baseSepolia.id]: http(),
    [polygonAmoy.id]: http(),
  },
  connectors: [embeddedWallet({ debug: true })],
  ssr: true,
});

// Add this type for the Providers props
type ProvidersProps = PropsWithChildren<{
  onSessionEnd?: () => void;
}>;

// This is so we can test in different environments including local, dev, and production
const config = {
  endpoints: {
    wallet: process.env.NEXT_PUBLIC_WALLET_API_BASE_URL,
    rpcs: {
      11155111: process.env.NEXT_PUBLIC_SEPOLIA_RPC,
    },
  },
  turnkey: {
    serverSignUrl: process.env.NEXT_PUBLIC_WALLET_API_BASE_URL
      ? `${process.env.NEXT_PUBLIC_WALLET_API_BASE_URL}/proxy`
      : undefined,
    defaultOrganizationId: process.env.NEXT_PUBLIC_TURNKEY_ORGANIZATION_ID,
  },
};

export const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={wagmiConfig}>
        <CivicAuthProvider
          iframeMode={"embedded"}
          {...config}
        >
          {children}
        </CivicAuthProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
};
