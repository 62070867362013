import React, { useEffect, useState } from "react";
import { useIframe } from "../hooks/useIframe.js";
import { useIsInIframe } from "../hooks/useIsInIframe.js";
import { CivicAuthIframeContainer } from "./CivicAuthIframeContainer.js";
import { BlockDisplay } from "./BlockDisplay.js";
import { LoadingIcon } from "./LoadingIcon.js";
import { CivicAuthLogoutIframeContainer } from "./CivicAuthLogoutIframeContainer.js";

const IFrameAndLoading = ({
  error,
  isLoading,
  showIframeOnLogout = false,
}: {
  error: Error | null;
  isLoading: boolean;
  showIframeOnLogout?: boolean;
}) => {
  const isInIframe = useIsInIframe();
  const [logoutIframeLoading, setLogoutIframeLoading] = useState(true);
  const {
    renderIframe,
    iframeIsVisible,
    setIframeIsVisible,
    logoutIframeIsVisible,
  } = useIframe();
  const showLoadingOverlay = isInIframe || isLoading;

  useEffect(() => {
    const handleErrorMessage = (event: MessageEvent) => {
      const thisURL = new URL(window.location.href);
      if (
        !(
          event.origin.endsWith("civic.com") || thisURL.hostname === "localhost"
        )
      ) {
        return;
      }

      // The login app has thrown an error, so we need to show the logout iframe
      if (
        event.data?.source === "civicloginApp" &&
        event.data?.type === "auth_error"
      ) {
        setLogoutIframeLoading(false);
        return;
      }

      setLogoutIframeLoading(true);
    };

    window.addEventListener("message", handleErrorMessage);
    return () => window.removeEventListener("message", handleErrorMessage);
  }, []);

  return (
    <>
      {renderIframe && (
        <div
          style={iframeIsVisible ? { display: "block" } : { display: "none" }}
        >
          <CivicAuthIframeContainer onClose={() => setIframeIsVisible(false)} />
        </div>
      )}

      <div
        style={
          showIframeOnLogout && logoutIframeIsVisible
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <CivicAuthLogoutIframeContainer isLoading={logoutIframeLoading} />
      </div>

      {error && (
        <BlockDisplay>
          <div>Error: {error?.message}</div>
        </BlockDisplay>
      )}

      {showLoadingOverlay && !error && (
        <BlockDisplay>
          <LoadingIcon />
        </BlockDisplay>
      )}
    </>
  );
};
export { IFrameAndLoading };
