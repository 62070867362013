import { useCivicAuthConfig } from "@/shared/hooks/useCivicAuthConfig.js";
import type { SessionData } from "@/types.js";
import { useEffect, useMemo, useState } from "react";
import { NextClientAuthenticationRefresher } from "@/nextjs/NextClientAuthenticationRefresher.js";
import { resolveAuthConfig } from "@/nextjs/config.js";
import { BrowserCookieStorage } from "@/lib/cookies.js";

const useRefresh = (session: SessionData | null) => {
  const authConfig = useCivicAuthConfig();

  const storage = useMemo(() => {
    const config = resolveAuthConfig(authConfig ?? {});
    return new BrowserCookieStorage(config?.cookies?.tokens ?? {});
  }, [authConfig]);

  // setup token autorefresh
  const [refresher, setRefresher] = useState<
    NextClientAuthenticationRefresher | undefined
  >(undefined);

  useEffect(() => {
    if (!authConfig) return;
    const abortController = new AbortController();
    const currentRefresher = refresher;

    NextClientAuthenticationRefresher.build({ ...authConfig }, storage).then(
      (newRefresher) => {
        if (abortController.signal.aborted) return;

        currentRefresher?.clearAutorefresh();
        setRefresher(newRefresher);
      },
    );

    return () => {
      abortController.abort();
      currentRefresher?.clearAutorefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authConfig, storage]); // Only depend on what actually changes

  useEffect(() => {
    if (session?.authenticated) {
      refresher?.setupAutorefresh();
    } else {
      refresher?.clearAutorefresh();
    }

    return () => refresher?.clearAutorefresh();
  }, [refresher, session?.authenticated]);
};

export { useRefresh };
