import { Turnkey, TurnkeyIframeClient } from "@turnkey/sdk-browser";
import type { ApiClientConfig } from "../../types.js";
import { CivicTurnkeyApiClient } from "./civicTurnkeyApiClient.js";
import type { WalletClient } from "viem";
import {
  loginAndGetWalletClient,
  type TurnkeyUser,
} from "./turnkeyApiClient.js";
import type { Web3Client } from "../Web3Client.js";

// Taken from @turnkey/sdk-react
const TurnkeyAuthIframeElementId = "turnkey-auth-iframe-element-id";
const TurnkeyAuthIframeContainerId = "turnkey-auth-iframe-container-id";

/**
 * The Turnkey implementation of the generic Web3Client interface.
 * Web3Client provides all clients (react and non-react) a common interface to
 * web3 wallet functionality, an address and a ViemClient, as well as a function to register a new wallet
 * This class implements this using Turnkey, hiding the details from the client.
 */
export class TurnkeyWeb3Client implements Web3Client {
  private civicTurnkeyApiClient: CivicTurnkeyApiClient;

  client: WalletClient | null; // will be null if the user does not yet have a wallet
  private sessionCheckTimeout: NodeJS.Timeout | null = null;

  constructor(
    private turnkey: { sdk: Turnkey; iframeClient: TurnkeyIframeClient },
    private user: TurnkeyUser,
    private config: ApiClientConfig,
    private onSessionEnd?: () => void,
  ) {
    this.civicTurnkeyApiClient = new CivicTurnkeyApiClient(user, config);
    this.client = null;
  }

  public get address(): string | null {
    return this.client?.account?.address ?? null;
  }
  private async checkSession() {
    const user = await this.turnkey.sdk.getCurrentUser();
    const session = user?.session;

    if (!session) {
      return;
    }

    const expiryTime = session.read?.expiry;
    const now = Date.now();

    if (expiryTime && expiryTime <= now) {
      this.onSessionEnd?.();
    } else {
      // Clear any existing timeout
      if (this.sessionCheckTimeout) {
        clearTimeout(this.sessionCheckTimeout);
      }
      // Set timeout to check again when session expires
      if (expiryTime) {
        this.sessionCheckTimeout = setTimeout(() => {
          this.checkSession();
        }, expiryTime - now);
      }
    }
  }

  private async init() {
    // now that everything's populated, login to turnkey to see if the user has a wallet
    try {
      this.client = await loginAndGetWalletClient(
        this.turnkey,
        this.user,
        this.config,
      );
    } catch (e) {
      console.error("Error logging in to turnkey", e);
      // If the user is already logged in but with a different email, log them out. Clear the state.
      this.turnkey.sdk.logoutUser();
      this.onSessionEnd?.();
      throw e;
    }
    // Start session checking if callback is provided
    if (this.onSessionEnd) {
      await this.checkSession();
    }
  }

  public async createWallet(): Promise<void> {
    await this.civicTurnkeyApiClient.createWallet();
    // refresh the client to get the new wallet
    await this.init();
  }

  public async disconnect(): Promise<void> {
    if (this.sessionCheckTimeout) {
      clearTimeout(this.sessionCheckTimeout);
      this.sessionCheckTimeout = null;
    }
    this.turnkey.sdk.logoutUser();
  }

  // Should *not* be used by react clients, rely on the useTurnkey hook to get the turnkey objects
  // non-react clients have to inject the iframe client themselves
  static async build(
    config: ApiClientConfig,
    user: TurnkeyUser,
    iframeContainerId?: string,
    onSessionEnd?: () => void,
  ): Promise<Web3Client> {
    // if an iframe container is not provided, create one
    if (!iframeContainerId) {
      const iframeContainer = document.createElement("div");
      iframeContainer.id = TurnkeyAuthIframeContainerId;
      document.body.appendChild(iframeContainer);
      iframeContainerId = TurnkeyAuthIframeContainerId;
    }

    // construct a turnkey object
    const turnkey = new Turnkey(config.turnkey);
    const iframeClient = await turnkey.iframeClient({
      iframeContainer: document.getElementById(iframeContainerId),
      iframeUrl: config.turnkey.iframeUrl,
      iframeElementId: TurnkeyAuthIframeElementId,
    });

    const client = new TurnkeyWeb3Client(
      { sdk: turnkey, iframeClient },
      user,
      config,
      onSessionEnd,
    );
    await client.init();
    return client;
  }

  static async buildReact(
    turnkey: { sdk: Turnkey; iframeClient: TurnkeyIframeClient },
    user: TurnkeyUser,
    config: ApiClientConfig,
    onSessionEnd?: () => void,
  ): Promise<Web3Client> {
    const client = new TurnkeyWeb3Client(turnkey, user, config, onSessionEnd);
    await client.init();
    return client;
  }
}
