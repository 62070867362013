/**
 * Convert any ethereum 1193 parameter fields where necessary.
 * Note - this does not provide type-safety: the output is still unknown,
 * so a cast will still be necessary.
 * Type-safety would require more complicated logic depending on the transaction type (1559, 7702 etc)
 * @param raw An object containing ethereum EIP-1193 request parameters
 */
export const normaliseEthRequestParams = (raw: unknown): unknown => {
  if (typeof raw !== "object" || !raw) {
    throw new Error("Expected an object.");
  }

  // Fields we want to convert to BigInt if they are strings
  const bigintFields = [
    "gas",
    "gasPrice",
    "maxFeePerGas",
    "maxPriorityFeePerGas",
    "nonce",
    "value",
    "chainId",
  ];

  // Make a copy
  const processed = { ...(raw as Record<string, unknown>) };

  // Convert specified fields from string to BigInt
  for (const field of bigintFields) {
    if (processed[field] && typeof processed[field] === "string") {
      processed[field] = BigInt(processed[field] as string);
    }
  }

  // Check addresses
  if (processed.from && !/^0x[a-fA-F0-9]{40}$/.test(processed.from as string)) {
    throw new Error("Invalid from address");
  }
  if (processed.to && !/^0x[a-fA-F0-9]{40}$/.test(processed.to as string)) {
    throw new Error("Invalid to address");
  }

  return processed;
};
