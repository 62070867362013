const DEFAULT_SCOPES = [
  "openid",
  "profile",
  "email",
  "forwardedTokens",
  "offline_access",
];
const DEFAULT_AUTH_SERVER = "https://auth.civic.com/oauth";

const DEFAULT_OAUTH_GET_PARAMS = ["code", "state", "iss"];

// The server's callback handler renders this text if it needs the front-end to make an additional token exchange call,
// for the iframe case where cookies are not sent along with the initial redirect.
const TOKEN_EXCHANGE_TRIGGER_TEXT = "sameDomainCodeExchangeRequired";

const TOKEN_EXCHANGE_SUCCESS_TEXT = "serverSideTokenExchangeSuccess";

const DEFAULT_DISPLAY_MODE = "iframe";
export {
  DEFAULT_SCOPES,
  DEFAULT_OAUTH_GET_PARAMS,
  DEFAULT_DISPLAY_MODE,
  DEFAULT_AUTH_SERVER,
  TOKEN_EXCHANGE_TRIGGER_TEXT,
  TOKEN_EXCHANGE_SUCCESS_TEXT,
};
