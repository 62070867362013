// Proposals for revised versions of the SessionService AKA AuthSessionService

import type {
  DisplayMode,
  Endpoints,
  IframeAuthMessage,
  LoginPostMessage,
  OIDCTokenResponseBody,
  SessionData,
} from "@/types.js";
import {
  BrowserPublicClientPKCEProducer,
  ConfidentialClientPKCEConsumer,
} from "@/services/PKCE.js";
import {
  clearTokens,
  clearUser,
  exchangeTokens,
  generateOauthLoginUrl,
  generateOauthLogoutUrl,
  getEndpointsWithOverrides,
  retrieveTokens,
  storeTokens,
  validateOauth2Tokens,
} from "@/shared/lib/util.js";
import { displayModeFromState, generateState } from "@/lib/oauth.js";
import { OAuth2Client } from "oslo/oauth2";
import { LocalStorageAdapter } from "@/browser/storage.js";
import type {
  AuthenticationInitiator,
  AuthenticationResolver,
  PKCEConsumer,
} from "@/services/types.js";
import { PopupError } from "@/services/types.js";
import { removeParamsWithoutReload } from "@/lib/windowUtil.js";
import { DEFAULT_OAUTH_GET_PARAMS } from "@/constants.js";
import { validateLoginAppPostMessage } from "@/lib/postMessage.js";
import { getUser } from "@/shared/lib/session.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";

export type GenericAuthenticationInitiatorConfig = {
  clientId: string;
  redirectUrl: string;
  state: string;
  scopes: string[];
  oauthServer: string;
  nonce?: string;
  // the endpoints to use for the login (if not obtained from the auth server)
  endpointOverrides?: Partial<Endpoints>;
  // used to get the PKCE challenge
  pkceConsumer: PKCEConsumer;
};

export type BrowserAuthenticationInitiatorConfig = Omit<
  GenericAuthenticationInitiatorConfig,
  "state"
> & {
  logoutUrl?: string;
  logoutRedirectUrl: string;
  // determines whether to trigger the login/logout in an iframe, a new browser window, or redirect the current one.
  displayMode: DisplayMode;
};
/**
 * An authentication initiator that works on a browser. Since this is just triggering
 * login and logout, session data is not stored here.
 * An associated AuthenticationResolver would be needed to get the session data.
 * Storage is needed for the code verifier, this is the domain of the PKCEConsumer
 * The storage used by the PKCEConsumer should be available to the AuthenticationResolver.
 *
 * Example usage:
 *
 * 1) Client-only SPA -eg a react app with no server:
 * new BrowserAuthenticationInitiator({
 *   pkceConsumer: new BrowserPublicClientPKCEProducer(), // generate and retrieve the challenge client-side
 *   ... other config
 * })
 *
 * 2) Client-side of a client/server app - eg a react app with a backend:
 * new BrowserAuthenticationInitiator({
 *  pkceConsumer: new ConfidentialClientPKCEConsumer("https://myserver.com/pkce"), // get the challenge from the server
 *  ... other config
 * })
 */
export class BrowserAuthenticationInitiator implements AuthenticationInitiator {
  private postMessageHandler: null | ((event: MessageEvent) => void) = null;

  protected config: BrowserAuthenticationInitiatorConfig;

  public setDisplayMode(displayMode: DisplayMode) {
    this.config.displayMode = displayMode;
  }

  get displayMode() {
    return this.config.displayMode;
  }

  get isServerTokenExchange() {
    return this.config.pkceConsumer instanceof ConfidentialClientPKCEConsumer;
  }
  get state() {
    return generateState(this.config.displayMode, this.isServerTokenExchange);
  }
  constructor(config: typeof this.config) {
    this.config = config;
  }

  async handleLoginAppPopupFailed(redirectUrl: string) {
    console.warn(
      "Login app popup failed open a popup, using redirect mode instead...",
      redirectUrl,
    );
    window.location.href = redirectUrl;
  }

  // Use the config (Client ID, scopes OAuth Server, Endpoints, PKCEConsumer) to generate a new login url
  // and then use the display mode to decide how to send the user there
  async signIn(iframeRef: HTMLIFrameElement | null): Promise<URL> {
    const url = await generateOauthLoginUrl({
      ...this.config,
      state: this.state,
    });

    this.postMessageHandler = (event: MessageEvent) => {
      const thisURL = new URL(window.location.href);
      if (
        event.origin.endsWith("civic.com") ||
        thisURL.hostname === "localhost"
      ) {
        if (!validateLoginAppPostMessage(event.data, this.config.clientId)) {
          return;
        }
        const loginMessage = event.data as LoginPostMessage;
        this.handleLoginAppPopupFailed(loginMessage.data.url);
      }
    };

    window.addEventListener("message", this.postMessageHandler);

    if (this.config.displayMode === "iframe") {
      if (!iframeRef)
        throw new Error("iframeRef is required for displayMode 'iframe'");
      iframeRef.setAttribute("src", url.toString());
    }

    if (this.config.displayMode === "redirect") {
      window.location.href = url.toString();
    }

    if (this.config.displayMode === "new_tab") {
      try {
        const popupWindow = window.open(url.toString(), "_blank");
        if (!popupWindow) {
          throw new PopupError("Failed to open popup window");
        }
        // TODO handle the 'onclose' event to clean up and reset the authStatus
      } catch (error) {
        console.error("popupWindow", error);
        throw new PopupError(
          "window.open has thrown: Failed to open popup window",
        );
      }
    }

    return url;
  }

  protected handleIframeUrlChange(
    iframe: HTMLIFrameElement,
    expectedUrl: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let interval: NodeJS.Timeout | undefined = undefined;
      let timeout: NodeJS.Timeout | undefined = undefined;

      const messageHandler = (event: MessageEvent) => {
        if (event.source !== iframe.contentWindow) {
          // This message did not originate from the iframe. Ignore it.
          return;
        }

        const message = event.data as IframeAuthMessage;

        if (
          message.source === "civicloginApp" &&
          message.type === "auth_error"
        ) {
          clearInterval(interval);
          clearTimeout(timeout);
          return;
        }

        if (
          message.source === "civicloginApp" &&
          message.type === "auth_error_try_again"
        ) {
          clearInterval(interval);
          clearTimeout(timeout);
          window.removeEventListener("message", messageHandler);
          reject(new Error(message.data.error || "Authentication failed"));
          return;
        }
      };

      window.addEventListener("message", messageHandler);

      // Keep the existing URL check logic for success case
      const checkIframe = () => {
        try {
          const currentUrl = iframe.contentWindow?.location.href;
          if (currentUrl?.includes(expectedUrl)) {
            clearInterval(interval);
            window.removeEventListener("message", messageHandler);
            resolve();
          }
        } catch {
          // Ignore cross-origin errors
        }
      };

      interval = setInterval(checkIframe, 100);

      // Timeout after 10 seconds
      timeout = setTimeout(() => {
        clearInterval(interval);
        window.removeEventListener("message", messageHandler);
        reject(new Error("Timeout waiting for iframe URL change"));
      }, 10000);
    });
  }

  async signOut(
    idToken: string | undefined,
    iframeRef: HTMLIFrameElement | null,
  ): Promise<URL> {
    let url;
    const state = this.state;
    if (this.isServerTokenExchange) {
      if (!this.config.logoutUrl) {
        throw new Error("logoutUrl is required for server token exchange");
      }
      url = new URL(this.config.logoutUrl, window.location.origin);
      url.searchParams.append("state", state);
    } else {
      if (!idToken) {
        throw new Error("idToken is required for non-server token exchange");
      }
      url = await generateOauthLogoutUrl({
        ...this.config,
        idToken,
        state,
        redirectUrl: this.config.logoutRedirectUrl,
      });
    }

    if (this.config.displayMode === "iframe") {
      if (!iframeRef) {
        throw new Error("iframeRef is required for displayMode 'iframe'");
      }
      iframeRef.setAttribute("src", url.toString());

      await this.handleIframeUrlChange(
        iframeRef,
        this.config.logoutRedirectUrl,
      );

      // Clear storage after successful detection
      if (!this.isServerTokenExchange) {
        const localStorage = new LocalStorageAdapter();
        await clearTokens(localStorage);
        await clearUser(localStorage);
        LocalStorageAdapter.emitter.emit("signOut");
      }
    }

    if (this.config.displayMode === "redirect") {
      const localStorage = new LocalStorageAdapter();
      localStorage.set("logout_state", state);
      window.location.href = url.toString();
    }

    if (this.config.displayMode === "new_tab") {
      try {
        const popupWindow = window.open(url.toString(), "_blank");
        if (!popupWindow) {
          throw new PopupError("Failed to open popup window");
        }
      } catch (error) {
        console.error("popupWindow", error);
        throw new PopupError(
          "window.open has thrown: Failed to open popup window",
        );
      }
    }

    return url;
  }

  cleanup() {
    if (this.postMessageHandler) {
      window.removeEventListener("message", this.postMessageHandler);
    }
  }
}

/** A general-purpose authentication initiator, that just generates urls, but lets
 * the caller decide how to use them. This is useful for server-side applications
 * that may serve this URL to their front-ends or just call them directly
 */
export class GenericAuthenticationInitiator implements AuthenticationInitiator {
  protected config: GenericAuthenticationInitiatorConfig;

  constructor(config: typeof this.config) {
    this.config = config;
  }

  // Use the config (Client ID, scopes OAuth Server, Endpoints, PKCEConsumer) to generate a new login url
  // and simply return the url
  async signIn(): Promise<URL> {
    return generateOauthLoginUrl(this.config);
  }

  async signOut(idToken: string): Promise<URL> {
    return generateOauthLogoutUrl({
      ...this.config,
      idToken,
    });
  }
}

type BrowserAuthenticationConfig = {
  clientId: string;
  redirectUrl: string;
  logoutUrl?: string;
  logoutRedirectUrl: string;
  scopes: string[];
  oauthServer: string;
  endpointOverrides?: Partial<Endpoints>;
  displayMode: DisplayMode;
};

/**
 * An authentication resolver that can run on the browser (i.e. a public client)
 * It uses PKCE for security. PKCE and Session data are stored in local storage
 */
export class BrowserAuthenticationService extends BrowserAuthenticationInitiator {
  private oauth2client: OAuth2Client | undefined;
  private endpoints: Endpoints | undefined;

  // TODO WIP - perhaps we want to keep resolver and initiator separate here
  constructor(
    config: BrowserAuthenticationConfig,
    // Since we are running fully on the client, we produce as well as consume the PKCE challenge
    protected pkceProducer = new BrowserPublicClientPKCEProducer(),
  ) {
    super({
      ...config,
      // Store and retrieve the PKCE challenge in local storage
      pkceConsumer: pkceProducer,
    });
  }

  // TODO too much code duplication here between the browser and the server variant.
  // Suggestion for refactor: Standardise the config for AuthenticationResolvers and create a one-shot
  // function for generating an oauth2client from it
  async init(): Promise<this> {
    // resolve oauth config
    this.endpoints = await getEndpointsWithOverrides(
      this.config.oauthServer,
      this.config.endpointOverrides,
    );
    this.oauth2client = new OAuth2Client(
      this.config.clientId,
      this.endpoints.auth,
      this.endpoints.token,
      {
        redirectURI: this.config.redirectUrl,
      },
    );

    return this;
  }

  // Two responsibilities:
  // 1. resolve the auth code to get the tokens (should use library code)
  // 2. store the tokens in local storage
  async tokenExchange(
    code: string,
    state: string,
  ): Promise<OIDCTokenResponseBody> {
    if (!this.oauth2client) await this.init();
    const codeVerifier = await this.pkceProducer.getCodeVerifier();
    if (!codeVerifier) throw new Error("Code verifier not found in storage");

    // exchange auth code for tokens
    const tokens = await exchangeTokens(
      code,
      state,
      this.pkceProducer,
      this.oauth2client!, // clean up types here to avoid the ! operator
      this.config.oauthServer,
      this.endpoints!, // clean up types here to avoid the ! operator
    );
    const clientStorage = new LocalStorageAdapter();
    await storeTokens(clientStorage, tokens);
    const user = await getUser(clientStorage);
    if (!user) {
      throw new Error("Failed to get user info");
    }
    const userSession = new GenericUserSession(clientStorage);
    await userSession.set(user);
    LocalStorageAdapter.emitter.emit("signIn");
    // cleanup the browser window if needed
    const parsedDisplayMode = displayModeFromState(
      state,
      this.config.displayMode,
    );

    if (parsedDisplayMode === "new_tab") {
      // Close the popup window
      window.addEventListener("beforeunload", () => {
        window?.opener?.focus();
      });
      window.close();
    }
    // these are the default oAuth params that get added to the URL in redirect which we want to remove if present
    removeParamsWithoutReload(DEFAULT_OAUTH_GET_PARAMS);
    return tokens;
  }

  // Get the session data from local storage
  async getSessionData(): Promise<SessionData | null> {
    const storageData = await retrieveTokens(new LocalStorageAdapter());

    if (!storageData) return null;

    return {
      authenticated: !!storageData.id_token,
      idToken: storageData.id_token,
      accessToken: storageData.access_token,
      refreshToken: storageData.refresh_token,
    };
  }

  async validateExistingSession(): Promise<SessionData> {
    try {
      const sessionData = await this.getSessionData();
      if (!sessionData?.idToken || !sessionData.accessToken) {
        const unAuthenticatedSession = { ...sessionData, authenticated: false };
        // await clearTokens(new LocalStorageAdapter());
        return unAuthenticatedSession;
      }
      if (!this.endpoints || !this.oauth2client) await this.init();

      // this function will throw if any of the tokens are invalid
      await validateOauth2Tokens(
        {
          access_token: sessionData.accessToken,
          id_token: sessionData.idToken,
          refresh_token: sessionData.refreshToken,
        },
        this.endpoints!,
        this.oauth2client!,
        this.config.oauthServer,
      );
      return sessionData;
    } catch (error) {
      console.warn("Failed to validate existing tokens", error);
      const unAuthenticatedSession = {
        authenticated: false,
      };
      await clearTokens(new LocalStorageAdapter());
      return unAuthenticatedSession;
    }
  }

  async getEndSessionEndpoint(): Promise<string | null> {
    if (!this.endpoints) {
      return null;
    }
    return this.endpoints?.endsession;
  }

  static async build(
    config: BrowserAuthenticationConfig,
  ): Promise<AuthenticationResolver> {
    const resolver = new BrowserAuthenticationService(config);
    await resolver.init();

    return resolver;
  }
}
