import { printVersion } from "@/shared/index.js";
printVersion();

export { CookieStorage } from "@/shared/lib/storage.js";
export type {
  SessionStorage,
  CookieStorageSettings,
} from "@/shared/lib/storage.js";
export {
  resolveOAuthAccessCode,
  isLoggedIn,
  buildLoginUrl,
  buildLogoutRedirectUrl,
} from "@/server/login.js";
export type { AuthConfig } from "@/server/config.js";
export { getUser, getTokens } from "@/shared/lib/session.js";
export { refreshTokens } from "@/server/refresh.js";
