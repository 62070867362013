// Helper functions that interact with the turnkey API (via the civic proxy) to login, get wallets etc

import {
  Turnkey,
  type TurnkeyBrowserClient,
  TurnkeyIframeClient,
  type TurnkeySDKApiTypes,
} from "@turnkey/sdk-browser";
import type { ForwardedTokens, User } from "@civic/auth";
import { createAccount } from "@turnkey/viem";
import {
  type Chain,
  createWalletClient,
  http,
  type LocalAccount,
  type Transport,
  type WalletClient,
} from "viem";
import { baseSepolia } from "viem/chains";
import type {
  CivicApiClientConfig,
  UserTurnkeyLoginInfo,
} from "../../types.ts";
import { jwtDecode } from "jwt-decode";
import { AnalyticsEmitter } from "../analytics/AnalyticsEmitter.js";
import { getTransportForChain } from "../walletUtils.js";

export type TurnkeyUser = User<{
  email: string;
  forwardedTokens: ForwardedTokens;
  idToken: string;
}>;

// This applies as long as:
// 1. The user object contains the id token fields
// 2. The id token "aud" field is the client_id of the relying party
const clientIdFromUser = (user: TurnkeyUser): string => {
  if (!user.idToken) {
    throw new Error(
      `User ${user.email} has no id token - cannot log in to Turnkey`,
    );
  }
  try {
    return (jwtDecode(user.idToken)?.aud as string) || "";
  } catch {
    throw new Error(
      `Error decoding id token for user ${user.email} - id token: ${user.idToken}`,
    );
  }
};

/**
 * Search for a suborg for this user and clientId, and, if found, log into it.
 * If the suborg does not exist, return null.
 * If the suborg exists but the login fails, throw an error.
 * @param turnkey
 * @param iframeClient
 * @param user
 */
export const loginToTurnkey = async (
  turnkey: Turnkey,
  iframeClient: TurnkeyIframeClient,
  user: TurnkeyUser,
): Promise<UserTurnkeyLoginInfo | null> => {
  console.log(`loginToTurnkey: User ${user.email} is logging into Turnkey`);
  const forwardedToken = Object.values(user.forwardedTokens || {})[0];
  const currentTurnkeyUser = await turnkey?.getCurrentUser();

  if (currentTurnkeyUser && currentTurnkeyUser.username !== user.email) {
    console.log(
      "loginToTurnkey: User already logged in but with a different email - log the user out",
    );
    // cleanup existing session and proceed with login
    await turnkey?.logoutUser();
  }

  if (!forwardedToken) {
    console.log(
      "loginToTurnkey: User not logged in yet - do not look for a suborg",
    );
    // This should not have been called - the user exists but is missing a forwarded token
    // either they did not log in to civic-auth via an oauth service like Google, or there was a bug
    throw new Error(
      "User not logged in via an oauth service - civic-auth login found but the id token contained no forwarded tokens",
    );
  }

  // find the turnkey suborgs for the user
  // we need to find the one that matches the current relying party ID (client_id)
  const expectedSubOrgName = `${clientIdFromUser(user)}-${user.email}`;
  const subOrgIds: TurnkeySDKApiTypes.TGetSubOrgIdsResponse =
    await turnkey.serverSign("getSubOrgIds", [
      // Works until a user has more than one - filter by name instead - keep for information purposes only
      // It is a useful way to find *all* suborgs for a user
      // {
      //   filterType: "OIDC_TOKEN",
      //   filterValue: forwardedToken.idToken,
      // },
      {
        filterType: "NAME",
        filterValue: expectedSubOrgName,
      },
    ])!;
  if (subOrgIds.organizationIds.length > 1) {
    console.warn(
      "loginToTurnkey: Multiple suborgs found for user. Using the first one",
      subOrgIds,
    );
  }
  const [targetSubOrgId] = subOrgIds.organizationIds.sort() as [string]; // cast is safe because we checked the length above
  if (!targetSubOrgId) {
    console.log("loginToTurnkey: no suborg found for user");
    return null;
  }

  // log into the suborg
  const oauthResponse: TurnkeySDKApiTypes.TOauthResponse =
    await turnkey.serverSign("oauth", [
      {
        oidcToken: forwardedToken.idToken,
        targetPublicKey: `${iframeClient.iframePublicKey}`,
        organizationId: targetSubOrgId,
      },
    ]);

  // inject the credentials from the oauth response into the iframe
  const credentialResponse = await iframeClient.injectCredentialBundle(
    oauthResponse.credentialBundle,
  );
  if (credentialResponse) {
    const loginResponse = await iframeClient.login();
    if (!loginResponse?.organizationId)
      throw new Error("Turnkey login failed: " + JSON.stringify(loginResponse));
  } else {
    throw new Error("Credential injection failed");
  }
  // credential injection done, now get the current session
  const currentUserSession = await turnkey?.currentUserSession();
  if (!currentUserSession) throw new Error("No current user session found");

  // get the suborg's wallets
  const walletsResponse = await currentUserSession.getWallets();
  if (!walletsResponse)
    throw new Error("No wallets found for current logged-in user");

  // get the suborg's users
  // TODO do this stuff in parallel
  const usersResponse = await currentUserSession.getUsers();
  if (usersResponse.users.length === 0)
    throw new Error("No users found in the suborg");
  // This may happen if we want the customer to cosign.
  if (usersResponse.users.length > 1)
    console.log("Multiple users found in the suborg", usersResponse.users);

  // find the user we are looking for
  const turnkeyUser = usersResponse.users.find(
    (u) => u.userEmail === user.email,
  );

  if (!turnkeyUser)
    throw new Error(`User with email ${user.email} not found in the suborg`);

  return {
    wallets: walletsResponse.wallets,
    subOrgId: targetSubOrgId,
    user: turnkeyUser,
  };
};

export const viemTransport = http();
// TODO use a civic proxy, and support multichain
// process.env.NEXT_PUBLIC_SEPOLIA_RPC!

// Create the viem account from the turnkey client and details.
// In addition, we wrap the sign methods in the account with analytics event emission using the wrapper design pattern.
async function createViemAccountObject(
  turnkeyClient: TurnkeyBrowserClient,
  organizationId: string,
  address: string,
  config: CivicApiClientConfig,
) {
  const turnkeyAccount = await createAccount({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: turnkeyClient as any,
    organizationId,
    signWith: address,
  });

  const analyticsEmitter = new AnalyticsEmitter({
    endpoint: config.endpoints?.analytics,
  });

  turnkeyAccount.signMessage = analyticsEmitter
    .wrapFn(turnkeyAccount.signMessage, "signMessage")
    .bind(turnkeyAccount);
  turnkeyAccount.signTransaction = analyticsEmitter
    .wrapFn(turnkeyAccount.signTransaction, "signTransaction")
    .bind(turnkeyAccount);
  turnkeyAccount.signTypedData = analyticsEmitter
    .wrapFn(turnkeyAccount.signTypedData, "signTypedData")
    .bind(turnkeyAccount);
  return turnkeyAccount;
}

export const makeTurnkeyWalletClient = async (
  turnkeyClient: TurnkeyBrowserClient,
  currentSession: TurnkeyBrowserClient,
  config: CivicApiClientConfig,
): Promise<WalletClient<Transport, Chain, LocalAccount>> => {
  const [organization] = await Promise.all([currentSession.getOrganization()]);
  if (!organization?.organizationData.organizationId)
    throw new Error("No organization found for current logged-in user");

  const walletsResponse = await currentSession.getWallets({
    organizationId: organization.organizationData.organizationId,
  });
  const wallets = walletsResponse.wallets;
  if (!wallets[0] || !wallets.length)
    throw new Error("No wallets found for current logged-in user");

  const walletAccounts = await currentSession.getWalletAccounts({
    walletId: wallets[0].walletId,
  });

  if (!walletAccounts?.accounts[0] || !walletAccounts?.accounts?.length)
    throw new Error("No accounts found for current wallet");

  const ethereumWallets = walletAccounts.accounts.filter(
    (account) => account.addressFormat === "ADDRESS_FORMAT_ETHEREUM",
  );

  if (!ethereumWallets[0] || !ethereumWallets?.length)
    throw new Error("No ethereum accounts found for current wallet");

  const turnkeyAccount = await createViemAccountObject(
    turnkeyClient,
    organization.organizationData.organizationId,
    ethereumWallets[0].address,
    config,
  );

  const initialChain = baseSepolia; // initial chain, can be switched later by the client
  const transport = getTransportForChain(config.endpoints?.rpcs ?? {})(
    initialChain,
  );

  const walletClient = createWalletClient({
    account: turnkeyAccount,
    chain: initialChain,
    transport,
  });

  console.log(
    "TurnkeyClient makeTurnkeyWalletClient: walletClient created - user is ready to use Turnkey",
  );
  return walletClient;
};

export const loginAndGetWalletClient = async (
  turnkey: { sdk: Turnkey; iframeClient: TurnkeyIframeClient },
  user: TurnkeyUser,
  config: CivicApiClientConfig,
): Promise<WalletClient | null> => {
  console.log("TurnkeyApiClient loginAndGetWalletClient: Logging into Turnkey");

  // now that everything's populated, long into turnkey to see if the user has a wallet
  const loginResult = await loginToTurnkey(
    turnkey.sdk,
    turnkey.iframeClient,
    user,
  );
  console.log(
    "TurnkeyClient loginAndGetWalletClient: loginResult",
    loginResult,
  );
  if (!loginResult) return null; // user does not have a wallet

  // get the current session - we use this to get the user's wallets from their suborg
  // (the other clients cannot do that - even the iframe client
  const currentSession = await turnkey.sdk.currentUserSession();

  if (!currentSession) throw new Error("No active turnkey session found");

  return makeTurnkeyWalletClient(turnkey.iframeClient, currentSession, config);
};
