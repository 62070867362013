"use client";
import React from "react";
import { AuthProvider } from "@/reactjs/providers/AuthProvider.js";
import { BrowserPublicClientPKCEProducer } from "@/services/PKCE.js";
import { UserProvider } from "@/shared/providers/UserProvider.js";
import { LocalStorageAdapter } from "@/browser/storage.js";
import { CivicAuthConfigProvider } from "@/shared/providers/CivicAuthConfigContext.js";
import { IframeProvider } from "@/shared/providers/IframeProvider.js";
import { ClientTokenExchangeSessionProvider } from "@/reactjs/providers/ClientTokenExchangeSessionProvider.js";
import { SessionProvider } from "@/shared/providers/SessionProvider.js";
import { useClientTokenExchangeSession } from "@/reactjs/hooks/useClientTokenExchangeSession.js";
import { TokenProvider } from "@/shared/providers/TokenProvider.js";
import { useAuth } from "@/shared/hooks/useAuth.js";
import type { AuthProviderProps } from "@/shared/providers/types.js";

type CivicAuthProviderProps = Omit<AuthProviderProps, "pkceConsumer">;

const WrapperUserProvider = ({ children }: { children: React.ReactNode }) => {
  const { signIn, signOut, displayMode, authStatus } = useAuth();
  return (
    <UserProvider
      storage={new LocalStorageAdapter()}
      signIn={signIn}
      signOut={signOut}
      user={null}
      authStatus={authStatus}
      displayMode={displayMode}
    >
      {children}
    </UserProvider>
  );
};
const WrapperSessionAuthProvider = ({
  children,
  ...props
}: CivicAuthProviderProps) => {
  const sessionData = useClientTokenExchangeSession();
  return (
    <SessionProvider {...sessionData}>
      <IframeProvider iframeMode={props.iframeMode}>
        <AuthProvider
          {...props}
          pkceConsumer={new BrowserPublicClientPKCEProducer()}
        >
          <TokenProvider>
            <WrapperUserProvider>{children}</WrapperUserProvider>
          </TokenProvider>
        </AuthProvider>
      </IframeProvider>
    </SessionProvider>
  );
};
const CivicAuthProvider = ({ children, ...props }: CivicAuthProviderProps) => {
  return (
    <CivicAuthConfigProvider
      oauthServer={props?.config?.oauthServer}
      clientId={props?.clientId}
      redirectUrl={props?.redirectUrl}
      nonce={props?.nonce}
      logoutRedirectUrl={props?.logoutRedirectUrl}
    >
      <ClientTokenExchangeSessionProvider>
        <WrapperSessionAuthProvider {...props}>
          {children}
        </WrapperSessionAuthProvider>
      </ClientTokenExchangeSessionProvider>
    </CivicAuthConfigProvider>
  );
};

export { CivicAuthProvider, type CivicAuthProviderProps };
