import type {
  ExistingWeb3UserContext,
  NewWeb3UserContext,
  Web3UserContextType,
} from "../types.js";
import {
  type Chain,
  http,
  type HttpTransportConfig,
  type Transport,
} from "viem";

export const isNewUser = (
  userContext: Web3UserContextType,
): userContext is NewWeb3UserContext =>
  !("walletAddress" in userContext && userContext.walletAddress);

export const userHasWallet = (
  userContext: Web3UserContextType,
): userContext is ExistingWeb3UserContext => !isNewUser(userContext);

export const getTransportForChain =
  (rpcs: Record<number, string>, config?: HttpTransportConfig) =>
  (chain: Chain): Transport =>
    rpcs[chain.id] ? http(rpcs[chain.id], config) : http(undefined, config);
