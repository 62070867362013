import { CookieStorage } from "@/shared/lib/storage.js";
import type { OAuthTokens, TokensCookieConfig } from "@/shared/lib/types.js";

const getWindowCookieValue = (
  requests: {
    key: string;
    window: Window;
    parseJson?: boolean;
  }[],
) => {
  const cookie = window.document.cookie;
  if (!cookie) return null;
  const cookies = cookie.split(";");
  const response: Record<string, string | Record<string, unknown>> = {};
  for (const c of cookies) {
    const [name, value] = c.trim().split("=");
    const request = requests.find((r) => r.key === name);
    if (value && request) {
      try {
        const decodeURIComponentValue = decodeURIComponent(value);
        response[request.key] = request.parseJson
          ? JSON.parse(decodeURIComponentValue)
          : decodeURIComponentValue;
      } catch {
        response[request.key] = value;
      }
    }
  }
  return response;
};

class BrowserCookieStorage extends CookieStorage {
  constructor(readonly config: Partial<TokensCookieConfig> = {}) {
    super({
      secure: true,
      httpOnly: false,
    });
  }

  async get(key: string): Promise<string | null> {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts && parts.length === 2) {
      return parts.pop()?.split(";").shift() ?? null;
    }
    return null;
  }

  /**
   * there is no client-side implementation for setting cookies
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async set(_key: OAuthTokens, _value: string): Promise<void> {
    throw new Error("Not implemented.");
  }
}

export { BrowserCookieStorage, getWindowCookieValue };
