const objectsAreEqual = (
  obj1: Record<string, unknown> | null | undefined,
  obj2: Record<string, unknown> | null | undefined,
): boolean => {
  if (obj1 === obj2) return true;

  if ((obj1 && !obj2) || (obj2 && !obj1)) {
    return false;
  }

  const keys1 = obj1 ? Object.keys(obj1) : [];
  const keys2 = obj2 ? Object.keys(obj2) : [];

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if ((obj1 || {})[key] !== (obj2 || {})[key]) return false;
  }

  return true;
};
export { objectsAreEqual };
