import { isWindowInIframe } from "@/lib/windowUtil.js";
import { useEffect, useState } from "react";

const useIsInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(true);
  useEffect(() => {
    if (typeof globalThis.window !== "undefined") {
      const isInIframeVal = isWindowInIframe(globalThis.window);
      setIsInIframe(isInIframeVal);
    }
  }, []);
  return isInIframe;
};
export { useIsInIframe };
