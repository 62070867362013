import { getUser as getUserInternal, getTokens } from "@civic/auth/server";
import { CivicTurnkeyApiClient } from "../lib/turnkey/civicTurnkeyApiClient.js";
import { type CivicApiClientConfig, type UnknownObject } from "../types.js";
import type { AuthStorage, User } from "@civic/auth";
import type { ServerUser } from "./serverTypes.js";
import { mergeWithDefault } from "../lib/utils.js";
import { DEFAULT_CONFIG } from "../constants.js";

// Cache the wallets for the user, so that several server components calling getUser()
// do not make several calls to the wallet API.
export const getWallets = async (
  user: UserWithIdToken,
  config?: CivicApiClientConfig,
) => {
  const mergedConfig = {
    ...DEFAULT_CONFIG,
    endpoints: mergeWithDefault(DEFAULT_CONFIG.endpoints, config?.endpoints),
    turnkey: mergeWithDefault(DEFAULT_CONFIG.turnkey, config?.turnkey),
  };
  const civicTurnkeyApiClient = new CivicTurnkeyApiClient(user, mergedConfig);
  return civicTurnkeyApiClient.getWallets();
};

export const getUser = async <T extends UnknownObject>(
  config: CivicApiClientConfig,
  authStorage: AuthStorage,
): Promise<ServerUser<T> | null> => {
  const user = await getUserInternal(authStorage);
  const tokens = await getTokens(authStorage);
  if (!user || !tokens?.idToken) {
    return null;
  }
  const userWithIdToken = {
    ...user,
    idToken: tokens?.idToken,
  } as UserWithIdToken;
  const wallets = await getWallets(userWithIdToken, config);

  if (!wallets || wallets.length === 0 || !wallets[0]) {
    return {
      ...user,
      walletAddress: null,
      wallet: null,
    } as ServerUser<T>;
  } else {
    return {
      ...user,
      walletAddress: wallets[0].walletAddress,
      wallet: wallets[0],
    } as ServerUser<T>;
  }
};

export type UserWithIdToken = User<{ idToken: string }>;

export const createWallet = async (
  config: CivicApiClientConfig,
  authStorage: AuthStorage,
) => {
  const user = await getUserInternal(authStorage);
  const tokens = await getTokens(authStorage);

  const mergedConfig = {
    ...DEFAULT_CONFIG,
    endpoints: mergeWithDefault(DEFAULT_CONFIG.endpoints, config.endpoints),
    turnkey: mergeWithDefault(DEFAULT_CONFIG.turnkey, config.turnkey),
  };
  if (!user || !tokens?.idToken) {
    throw new Error("create wallet requires a user and an idToken");
  }
  const userWithIdToken = { ...user, idToken: tokens?.idToken };
  const civicTurnkeyApiClient = new CivicTurnkeyApiClient(
    userWithIdToken,
    mergedConfig,
  );
  const wallet = await civicTurnkeyApiClient.createWallet();

  // let the frontend know about the new wallet
  // note - the frontend can also find out by simply reloading - it doesn't rely on this cookie
  // for the wallet information
  authStorage.set("civic-embedded-wallet", JSON.stringify(wallet));

  return;
};
