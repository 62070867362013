"use client";
import { Logo } from "@/components/logo";
import { UserButton, useUser } from "@civic/auth-web3/react";

const Header = () => {
  const { user, isLoading } = useUser();

  return (
    <div className="items-center justify-between px-2 py-4 sm:flex">
      <Logo className="mb-4 sm:mb-0 mx-auto sm:mx-0" />
      {(user || isLoading) && <UserButton />}
    </div>
  );
};

export { Header };
