"use client";
import React, { forwardRef } from "react";

type CivicAuthIframeProps = {
  onLoad?: () => void;
  id: string;
};

const CivicAuthIframe = forwardRef<HTMLIFrameElement, CivicAuthIframeProps>(
  ({ onLoad, id }, ref) => {
    return (
      <iframe
        id={id}
        ref={ref}
        style={{ height: "28rem", width: "100%", border: "none" }}
        onLoad={onLoad}
      />
    );
  },
);

CivicAuthIframe.displayName = "CivicAuthIframe";

export type { CivicAuthIframeProps };

export { CivicAuthIframe };
